import React, { Component } from "react";

export default class Policy extends Component {
  render() {
    return (
      <div style={{ padding: 60, marginTop: 40 }}>
        <h1>
          Dear play console privacy reviewer team member, please mention in red
          whats wrong in my privacy policy.
        </h1>
        <h2>Privacy Policy</h2>
        <p>I. Introduction</p>
        <p>
          The door2door and d2d truck Freight mobile applications and website
          match carriers with shippers. To enable these applications and
          websites (the “services”), door2door and d2d truck and its affiliates
          (collectively, “door2door and d2d truck Freight”) collect and use
          certain information about our users when they use or communicate with
          us regarding our services. This notice describes the personal data we
          collect from users of the services, how this data is used and shared,
          and users’ choices regarding this data.
        </p>
        <p>Last modified: May 29, 2021</p>
        <p>Effective date: December 31, 2023</p>
        <p>II. Overview</p>
        <p>A. Scope and application</p>
        <p>
          This notice applies to users of door2door and d2d truck Freight’s
          services anywhere in the world.
        </p>
        <p>
          This notice applies to all users of door2door and d2d truck Freight’s
          apps, websites, features, or other services anywhere in the world,
          unless covered by a separate privacy notice, such as the door2door and
          d2d truck User Privacy Notice. This notice specifically applies to
          door2door and d2d truck Freight:
        </p>
        <p>
          - Carriers: individuals or entities that offer motor vehicles used to
          transport goods
        </p>
        <p>
          - Commercial drivers: individuals licensed to operate motor vehicles
          to transport goods for or on behalf of carriers
        </p>
        <p>
          - Shippers: any user of the services that is identified as the
          shipper, consignor, or consignee, or that owns the goods being
          transported
        </p>
        <p>
          - Dispatchers: individuals who assign motor vehicles and commercial
          drivers for conveyance of freight
        </p>
        <p>
          All those subject to this notice are referred to as “users” in this
          notice.
        </p>
        <p>
          The practices described in this notice are subject to applicable laws
          in the places in which we operate. This means that we engage in the
          practices described in this notice in a particular country or region
          only if permitted under the laws of those places. Please contact us
          here or through the addresses below with any questions regarding our
          practices in a particular country or region.
        </p>
        <p>B. Data controller and transfer</p>
        <p>
          door2door LLC is the data controllers for the personal data collected
          in connection with the use of door2door and d2d truck Freight’s
          services in the Armenia.
        </p>
        <p>
          <br />
        </p>
        <p>
          Questions, comments, and complaints about door2door and d2d truck
          Freight’s data practices can be submitted here.
        </p>
        <p>III. Data collections and uses</p>
        <p>A. The data we collect</p>
        <p>door2door and d2d truck Freight collects:</p>
        <p>
          - Data provided by users to door2door and d2d truck Freight, such as
          during account creation
        </p>
        <p>
          - Data created during use of our services, such as location, app
          usage, and device data
        </p>
        <p>
          - Data from other sources, such as door2door and d2d truck Freight
          partners and third parties that use door2door and d2d truck Freight
          APIs
        </p>
        <p>
          The following data is collected by or on behalf of door2door and d2d
          truck Freight:
        </p>
        <p>Data provided by users. This includes:</p>
        <p>
          - User profile: We collect data when users create, modify, or log in
          to their account; when they request support from or otherwise
          communicate with us; and if they interact with the services as a
          commercial driver or carrier dispatcher, submit information about
          their vehicle or insurance, or otherwise communicate with us. This may
          include their name, email address, phone number, postal address,
          vehicle registration information, insurance information, payment
          information, and other information they choose to provide. For users
          outside the EEA, this may also include login name and password,
          profile picture, and government identification documents, including
          driver’s license numbers and images, birthdate, signature, and photo.
        </p>
        <p>
          - Demographic data: We may collect demographic data about users,
          including through user surveys. We may also receive demographic data
          about users from third parties.
        </p>
        <p>
          - Data submitted through communications with door2door and d2d truck:
          We collect the information users submit when they contact door2door
          and d2d truck Freight customer support, provide facility ratings, or
          otherwise contact door2door and d2d truck Freight. This may include
          feedback, photographs, or other recordings collected by users.
        </p>
        <p>Data created during use of our services. This includes:</p>
        <p>
          - Location data: Our services require that we collect precise or
          approximate location data from commercial drivers’ and dispatchers’
          mobile devices. We only collect such data where users have granted us
          permission to do so through the permission system used by their mobile
          device.
        </p>
        <p>
          For users located outside the EEA, door2door and d2d truck Freight
          collects this data when the door2door and d2d truck Freight app is
          running in the foreground (app open and on-screen) or background (app
          open but not on-screen) of their mobile device.
        </p>
        <p>
          For users located in the EEA, door2door and d2d truck Freight collects
          this data when the door2door and d2d truck Freight app is running in
          the foreground (app open and on-screen) of their mobile device. While
          actively on a load, we also collect this data when the door2door and
          d2d truck Freight app is running in the background (app open but not
          on-screen) of their mobile device, from dispatch until dropoff of a
          load.
        </p>
        <p>
          - Transaction information: We collect transaction information related
          to the use of our services, including the type of services requested
          or provided, date and time the service was provided, distance
          traveled, payment amounts, and other related transaction details.
        </p>
        <p>
          - Usage data: We collect data about how users interact with our
          services (including analytics that we or third parties conduct for the
          purpose of improving our services). This includes data such as access
          dates and times, app features and/or pages viewed, app crashes and
          other system activity, type of browser, and third-party sites or
          services used before interacting with our services. In some cases, we
          collect this data through cookies, pixels, tags, and similar tracking
          technologies that create and maintain unique identifiers. To learn
          more about these technologies, please see our Cookie Notice.
        </p>
        <p>
          - Device data: We may collect data about the devices used to access
          our services, including the hardware models, device IP address,
          operating systems and versions, software and file names and versions,
          preferred language, unique device identifiers, advertising
          identifiers, serial numbers, device motion data, and mobile network
          data.
        </p>
        <p>
          - Communications data: We enable users to interact with one another
          and door2door and d2d truck Freight through door2door and d2d truck
          Freight’s mobile apps and websites. To provide this service, door2door
          and d2d truck Freight receives some data regarding the calls, texts,
          or other communications, including the date and time of the
          communications and the content of the communications. door2door and
          d2d truck Freight may also use this data for customer support services
          (including to resolve disputes between users), for safety and security
          purposes, to improve our products and services, and for analytics.
        </p>
        <p>Data from other sources. For example:</p>
        <p>
          - We may receive information about commercial drivers from other users
          such as carriers or shippers, or from other third parties. This
          includes information about their location, or feedback they receive
          from other door2door and d2d truck Freight users.
        </p>
        <p>
          - We might receive information about a commercial driver or carrier
          from the regulatory bodies involved in the regulation of carriers.
        </p>
        <p>
          - If users engage with a separate app or website that uses our API (or
          whose API we use), we may receive information about them or their
          connections from that site or app.
        </p>
        <p>
          - We may receive information from publicly available sources and
          marketing service providers for lead generation. In the EEA, we will
          send users a notification if we collect their information through
          these resources.
        </p>
        <p>
          - If users also interact with other door2door and d2d truck products
          and services, for instance to request or provide services through
          other door2door and d2d truck apps, we may combine or associate that
          information with information we have collected from them in their
          capacity as a user.
        </p>
        <p>
          door2door and d2d truck Freight may combine the data collected from
          these sources with other data in its possession.
        </p>
        <p>B. How we use personal data</p>
        <p>
          door2door and d2d truck Freight collects and uses data to arrange for
          reliable and convenient transportation of shipments, delivery, and
          other products and services. We also use the data we collect:
        </p>
        <p>- To enhance the safety and security of our users and services</p>
        <p>- For customer support</p>
        <p>- For research and development</p>
        <p>- To send marketing and non-marketing communications to users</p>
        <p>- In connection with legal proceedings</p>
        <p>
          door2door and d2d truck Freight does not sell or share user personal
          data with third parties for their direct marketing, except with users’
          consent.
        </p>
        <p>
          door2door and d2d truck Freight uses the data it collects for purposes
          including:
        </p>
        <p>Providing services and features.</p>
        <p>
          door2door and d2d truck Freight uses the data we collect to provide,
          personalize, maintain, and improve our products and services. This
          includes using the data to:
        </p>
        <p>- Create and update users’ accounts</p>
        <p>
          - Enable the matching of shipments with carriers and/or commercial
          drivers
        </p>
        <p>
          - Track the status and location of shipments transported through the
          services, and determine other data relating to use of the services,
          such as how long a carrier and/or commercial driver has been at a
          pickup location to determine detention payments
        </p>
        <p>
          - Send communications to other users of the services, such as
          shippers, regarding the progress of shipments transported through the
          services, such as estimated delivery arrival and delivery times
        </p>
        <p>
          - Personalize and improve the services, including to develop new
          features and to provide or recommend shipments, features, content, and
          advertisements
        </p>
        <p>- Offer, process, or facilitate payments for the service</p>
        <p>
          - Perform internal operations necessary to provide our services,
          including to troubleshoot software bugs and operational problems; to
          conduct data analysis, testing, and research; and to monitor and
          analyze usage and activity trends
        </p>
        <p>Safety and security.</p>
        <p>
          We use personal data to help maintain the safety, security, and
          integrity of our services and users.
        </p>
        <p>This includes:</p>
        <p>
          - Using device, location, profile, usage, and other data to prevent,
          detect, and combat fraud or unsafe activities
        </p>
        <p>- Authenticating users</p>
        <p>- Developing safety features</p>
        <p>Customer support.</p>
        <p>
          door2door and d2d truck Freight uses the information we collect
          (including recordings of customer support calls with notice to and the
          consent of the user) to provide customer support, including to:
        </p>
        <p>- Direct questions to the appropriate customer support person</p>
        <p>- Investigate and address user concerns</p>
        <p>
          - Monitor and improve our customer support responses and processes
        </p>
        <p>Research and development.</p>
        <p>
          We may use the data we collect for testing, research, analysis,
          product development, and machine learning to improve the user
          experience. This helps us to improve and enhance the safety and
          security of our services, improve our ability to prevent the use of
          our services for illegal or improper purposes, develop new features
          and products, and facilitate finance solutions in connection with our
          services.
        </p>
        <p>Marketing.</p>
        <p>
          door2door and d2d truck Freight may use the data we collect to market
          our services to our users. This includes sending users communications
          about door2door and d2d truck Freight services, features, promotions,
          sweepstakes, studies, surveys, news, updates, and events. For further
          details about third-party marketing, see below. We may use the data we
          collect to personalize the marketing communications (including
          advertisements) that we send, including based on user location, past
          use of door2door and d2d truck Freight’s services, and user
          preferences and settings.
        </p>
        <p>Non-marketing communications.</p>
        <p>
          door2door and d2d truck Freight may use the data we collect to
          generate and provide users with receipts; inform them of changes to
          our terms, services, or policies; or send other communications that
          aren’t for the purpose of marketing the services or products of
          door2door and d2d truck Freight or its partners.
        </p>
        <p>Legal proceedings and requirements.</p>
        <p>
          We may use the personal data we collect to investigate or address
          claims or disputes relating to use of door2door and d2d truck
          Freight’s services, or as otherwise allowed by applicable law, or as
          requested by regulators, government entities, and official inquiries.
        </p>
        <p>C. Cookies and third-party technologies</p>
        <p>
          door2door and d2d truck Freight and its partners use cookies and other
          identification technologies on our apps, websites, emails, and online
          ads for purposes described in this notice.
        </p>
        <p>
          Cookies are small text files that are stored on browsers or devices by
          websites, apps, online media, and advertisements. door2door and d2d
          truck Freight uses cookies and similar technologies for purposes such
          as:
        </p>
        <p>- Authenticating users</p>
        <p>- Remembering user preferences and settings</p>
        <p>- Determining the popularity of content</p>
        <p>
          - Delivering and measuring the effectiveness of advertising campaigns
        </p>
        <p>
          - Analyzing site traffic and trends, and generally understanding the
          online behaviors and interests of people who interact with our
          services
        </p>
        <p>
          We may also allow others to provide audience measurement and analytics
          services for us, to serve advertisements on our behalf across the
          Internet, and to track and report on the performance of those
          advertisements. These entities may use cookies, web beacons, SDKs, and
          other technologies to identify the devices used by visitors to our
          websites, as well as when they visit other online sites and services.
        </p>
        <p>
          Please see our Cookie Notice for more information regarding the use of
          cookies and other technologies described in this section.
        </p>
        <p>D. Data sharing and disclosure</p>
        <p>
          Some of door2door and d2d truck Freight’s products, services, and
          features require that we share data with other users or at a user’s
          request. We may also share data with our affiliates, subsidiaries, and
          partners, for legal reasons or in connection with claims or disputes.
        </p>
        <p>door2door and d2d truck Freight may share the data we collect:</p>
        <p>With other users or third parties</p>
        <p>This includes sharing data:</p>
        <p>
          With other users or with third parties who require, request, or use
          such data in connection with transportation, logistics, delivery,
          and/or other related services arranged or performed by door2door and
          d2d truck Freight, such as rewards partners for fuel discounts and
          similar discount programs. We may also share commercial drivers’
          names, vehicle license numbers, vehicle truck numbers, and approximate
          location with other users or third parties such as operators of a
          receiving facility. We may also share their vehicle truck number and
          vehicle license number with third parties, such as a pickup facility.
          We may also share carriers’ names with other users.
        </p>
        <p>At users’ request</p>
        <p>This includes sharing data:</p>
        <p>
          - With other users in connection with services or features that
          require data sharing.
        </p>
        <p>
          - With third parties in connection with door2door and d2d truck
          partnerships or promotions with those third parties. For example, if a
          user requests a service through a partnership or promotional offering
          made by a third party, door2door and d2d truck Freight may share
          certain data with those third parties. This may include, for example,
          other services, platforms, apps, or websites that integrate with our
          APIs; those with an API or service with which we integrate; or other
          door2door and d2d truck Freight business partners and their users in
          connection with promotions, contests, or specialized services. The
          handling of such data by those third parties is subject to their
          privacy notices, and users should refer to those notices for more
          information on the handling of their data.
        </p>
        <p>With the general public</p>
        <p>
          Questions or comments from users submitted through public forums, such
          as blogs and social media pages, may be viewable by the public,
          including any personal data included in the questions or comments
          submitted by a user, or other features of the services that are
          viewable by the general public.
        </p>
        <p>With door2door and d2d truck affiliates and third parties</p>
        <p>
          We share data with door2door and d2d truck affiliates to help us
          provide our services or conduct data processing on our behalf, or for
          data centralization and/or logistics purposes. We also share data with
          third parties with whom users choose to let us share data, for example
          other apps or websites that integrate with our API or services, or
          those with an API or service with which we integrate.
        </p>
        <p>
          With door2door and d2d truck Freight service providers and business
          partners
        </p>
        <p>
          door2door and d2d truck Freight provides data to vendors, consultants,
          marketing partners, research firms, and other service providers or
          business partners. These include:
        </p>
        <p>- Payment processors and facilitators</p>
        <p>- Cloud storage providers</p>
        <p>
          - Marketing partners and marketing platform providers, including
          social media advertising services
        </p>
        <p>-Data analytics providers</p>
        <p>
          -Research partners, including those performing surveys or research
          projects in partnership with door2door and d2d truck Freight or on
          door2door and d2d truck Freight’s behalf
        </p>
        <p>
          -Vendors that assist door2door and d2d truck Freight to enhance the
          safety and security of its apps
        </p>
        <p>
          -Consultants, lawyers, accountants, and other professional service
          providers
        </p>
        <p>For legal reasons in the event of a dispute</p>
        <p>
          door2door and d2d truck Freight may share users’ personal data if we
          believe it’s required by applicable law, regulation, operating license
          or agreement, legal process or governmental request, or where the
          disclosure is otherwise appropriate due to safety or similar concerns.
        </p>
        <p>
          This includes sharing personal data with law enforcement officials,
          public health officials, other government authorities, or other third
          parties as necessary to enforce our Terms and Conditions, user
          agreements, or other policies; to protect door2door and d2d truck
          Freight’s rights or property or the rights, safety, or property of
          others; or in the event of a claim or dispute relating to the use of
          our services. In addition, where a user uses another person’s credit
          card, we may be required by law to share that user’s personal data
          with the owner of that credit card.
        </p>
        <p>
          This also includes sharing personal data with others in connection
          with, or during negotiations of, any merger, sale of company assets,
          consolidation or restructuring, financing, or acquisition of all or a
          portion of our business by or into another company.
        </p>
        <p>Through social media sharing</p>
        <p>
          The services may integrate with social media sharing features and
          other related tools, such as WhatsApp, which let users share actions
          they take on the services with other apps, sites, or media, and vice
          versa. Their use of such features enables the sharing of data with
          others, depending on the settings they establish with the social media
          sharing service. Please refer to the privacy notices of those social
          media sharing services for more information about how they handle the
          data provided to or shared through them.
        </p>
        <p>With consent</p>
        <p>
          door2door and d2d truck Freight may share a user’s personal data other
          than as described in this notice, if we notify the user and they
          consent to the sharing.
        </p>
        <p>E. Data retention and deletion</p>
        <p>
          door2door and d2d truck Freight retains user profile, transaction, and
          other personal data for as long as a user maintains their door2door
          and d2d truck Freight account.
        </p>
        <p>
          door2door and d2d truck Freight retains transaction, location, usage,
          and other information for as long as necessary, such as in connection
          with regulatory, tax, insurance, or other requirements in the places
          where it operates.
        </p>
        <p>
          Users may request deletion of their accounts at any time. Following
          such request, door2door and d2d truck Freight deletes the data it is
          not required, or has no legitimate business purpose, to retain and
          restricts access to or use of remaining information it does retain.
        </p>
        <p>
          door2door and d2d truck Freight retains user profile and transaction
          information for as long as a user maintains their door2door and d2d
          truck Freight account. For carriers and/or commercial drivers,
          door2door and d2d truck Freight also retains information relating to
          their vehicle and licenses (to the extent permitted by law) for as
          long as they maintain their door2door and d2d truck Freight account.
        </p>
        <p>
          door2door and d2d truck Freight retains location, device, and usage
          data for as long as necessary to provide services and comply with
          applicable regulatory, tax, insurance, litigation, and other legal
          requirements. door2door and d2d truck Freight may also use such
          information for purposes of safety, security, fraud prevention and
          detection, and research and development for as long as it retains such
          information.
        </p>
        <p>
          Users outside the EEA may request the deletion of their account by
          emailing support@d2d.am . Users in the EEA may request deletion of
          their account at any time by emailing support@d2d.am and d2d
          truck.com. Following such requests, door2door and d2d truck Freight
          deletes the data that it is not required to retain for purposes of
          regulatory, tax, insurance, litigation, or other legal requirements.
          In certain circumstances, door2door and d2d truck Freight may be
          unable to delete a user’s account, such as if there’s an outstanding
          credit on the account or an unresolved claim or dispute. Upon
          resolution of the issue preventing deletion, door2door and d2d truck
          Freight will delete the account as described above.
        </p>
        <p>
          door2door and d2d truck Freight may also retain certain information if
          necessary for purposes of safety, security, and fraud prevention. For
          example, if we deactivate a user’s account because of unsafe behavior
          or security incidents, we may retain certain information about that
          account to prevent that user from opening a new door2door and d2d
          truck Freight account in the future.
        </p>
        <p>F. Grounds for processing</p>
        <p>
          We only collect and use personal data where we have lawful grounds to
          do so. These include processing user personal data to provide
          requested services and features, for purposes of door2door and d2d
          truck Freight’s legitimate interests or those of other parties, to
          fulfill our legal obligations, or based on consent.
        </p>
        <p>
          We collect and use personal data only where we have one or more lawful
          grounds for doing so. Such grounds may vary depending on where our
          users are located, but generally include processing personal data:
        </p>
        <p>To provide requested services and features</p>
        <p>
          In order to provide our services, we must collect and use certain
          personal data. This includes:
        </p>
        <p>
          -User profile data, which we use to establish and maintain user
          accounts; verify user identity; communicate with users about their
          booked loads, trips, and accounts; and enable users to make payments
          or receive earnings
        </p>
        <p>
          - Location data, which is used to track loads and assist with
          navigation
        </p>
        <p>
          - Usage data, which is necessary to maintain, optimize, and enhance
          door2door and d2d truck Freight’s services, including to determine
          incentives, connect loads and commercial drivers, and calculate
          payments
        </p>
        <p>- Transaction information</p>
        <p>- Information relating to customer support</p>
        <p>
          For purposes of the legitimate interests of door2door and d2d truck
          Freight or other parties
        </p>
        <p>
          This includes using personal data to maintain and enhance our users’
          safety and security. For example, we use personal data to prevent use
          of our services by users who have engaged in inappropriate or
          dangerous behavior, such as by retaining data of banned users to
          prevent their use of door2door and d2d truck Freight’s apps.
        </p>
        <p>
          This also includes purposes such as combating fraud; improving our
          services, direct marketing, machine learning, and research and
          development; and enforcing door2door and d2d truck Freight’s Terms and
          Conditions.
        </p>
        <p>
          In addition, it includes using personal data to the extent necessary
          for the interests of other people or the general public, such as in
          connection with legal or insurance claims, and to protect the rights
          and safety of others.
        </p>
        <p>
          To fulfill door2door and d2d truck Freight’s legal obligations
          door2door and d2d truck Freight is subject to the laws and regulations
          in the jurisdictions in which it operates that may require it to
          collect, process, retain, and disclose users’ personal data. We
          collect and use personal data to comply with such laws.
        </p>
        <p>
          door2door and d2d truck Freight may also share data with law
          enforcement regarding criminal acts or threats to public safety, or
          requests by third parties pursuant to legal processes. For more
          information about such sharing, please see door2door and d2d truck’s
          Guidelines for Law Enforcement Authorities - United States, Guidelines
          for Law Enforcement Authorities - Outside the US, and Guidelines for
          Third Party Data Requests and Service of Legal Documents.
        </p>
        <p>
          With consent door2door and d2d truck Freight may collect and use
          personal data based on the user’s consent. For example, we may collect
          personal data through voluntary surveys.
        </p>
        <p>
          Users may revoke such consent at any time. However, they will not be
          able to use any service or feature that requires collection or use of
          that personal data.
        </p>
        <p>IV. Choice and transparency</p>
        <p>
          door2door and d2d truck Freight enables users to access and control
          the data that it collects, including through:
        </p>
        <p>- In-app settings</p>
        <p>- Device permissions</p>
        <p>- Marketing opt-outs</p>
        <p>
          door2door and d2d truck Freight also enables users to request access
          to or copies of their data, changes or updates to their accounts,
          deletion of their accounts, or that door2door and d2d truck Freight
          restrict its processing of user personal data.
        </p>
        <p>Device permissions</p>
        <p>
          Most mobile device platforms (iOS, Android, etc.) have defined certain
          types of device data that apps cannot access without the device
          owner’s permission, and these platforms have different methods for how
          that permission can be obtained. iOS devices notify users the first
          time the door2door and d2d truck Freight app requests permission to
          access certain types of data and gives users the option to grant or
          refuse permission. Android devices notify users of the permissions
          that the door2door and d2d truck Freight app seeks before their first
          use of the app, and use of the app constitutes a grant of such
          permission.
        </p>
        <p>- Location data</p>
        <p>
          We request permission for our app's collection of precise location
          from a user’s device (whether their own device or otherwise) per the
          permission system used by their mobile operating system. If they
          initially permit the collection of this data, they can later disable
          it by changing the location settings on their mobile device. However,
          they may not be able to use the door2door and d2d truck Freight app if
          they disable our collection of precise location data. Additionally,
          disabling our collection of precise location from their device will
          not limit our ability to derive an approximate location from their IP
          address to personalize the content of the websites and/or app.
        </p>
        <p>- Important information about device permissions</p>
        <p>
          The permissions requested by the door2door and d2d truck Freight app
          include permission to collect users’ location data for the purposes
          described above. For users outside the EEA, this permission enables
          door2door and d2d truck Freight to collect this data when the
          door2door and d2d truck Freight app is running in the foreground (app
          open and on-screen) or background (app open but not on-screen) of
          their mobile device.
        </p>
        <p>
          For users in the EEA, this permission enables door2door and d2d truck
          Freight to collect this data when the door2door and d2d truck Freight
          app is running in the foreground (app open and on-screen) of their
          mobile device. If a user is actively on a load, we also collect this
          data when the door2door and d2d truck Freight app is running in the
          background (app open but not on-screen) of their mobile device, from
          dispatch until dropoff of a load.
        </p>
        <p>
          Carriers and commercial drivers cannot use the door2door and d2d truck
          Freight app if they do not permit location data collection.
        </p>
        <p>
          Please take the following steps to disable the door2door and d2d truck
          Freight app's ability to collect precise location data:
        </p>
        <p>On iOS</p>
        <p>
          Settings → Privacy → Location Services → door2door and d2d truck
          Freight → choose Never
        </p>
        <p>On Android</p>
        <p>
          Settings → Apps → door2door and d2d truck Freight → scroll to
          Permission → toggle Location
        </p>
        <p>
          On Android Lollipop (5.1) and earlier Settings → Location → toggle Off
        </p>
        <p>
          - Notifications: account and updates door2door and d2d truck Freight
          provides users with notifications and updates related to activity on
          their account and booked loads. These notifications are a necessary
          part of using the door2door and d2d truck Freight app and cannot be
          disabled.
        </p>
        <p>
          - Notifications: potential loads and news Users may enable door2door
          and d2d truck Freight to send push notifications about potential loads
          and news from door2door and d2d truck Freight. Push notifications may
          be enabled or disabled through Settings on iOS or Android.
        </p>
        <p>Marketing opt-outs</p>
        <p>
          Users may opt out of receiving marketing emails and other messages
          from door2door and d2d truck Freight by following the unsubscribe
          instructions in those messages. We may still send opted-out users
          non-promotional communications, such as those about their account,
          services requested, or our ongoing business relations.
        </p>
        <p>User data requests</p>
        <p>
          door2door and d2d truck Freight provides users with a variety of ways
          to learn about, control, and submit requests, questions, and comments
          about door2door and d2d truck Freight’s handling of their data. Users
          may submit these by email to support@d2d.am d2d truck.com if outside
          the EEA, and to support@d2d.am truck.com if in the EEA.
        </p>
        <p>
          - Accessing data: Users can ask for an explanation of the data we
          collect from them and how we use it.
        </p>
        <p>
          - Receiving data: Users can ask for a copy of data that door2door and
          d2d truck Freight has collected from them with their consent or as
          necessary to provide our services.
        </p>
        <p>
          - Changing or updating data: Users can ask that door2door and d2d
          truck Freight change or update their data, including if they believe
          such data is inaccurate or incomplete.
        </p>
        <p>
          - Deleting data: Users may request deletion of their account at any
          time by emailing support@d2d.am d2d truck.com if outside the EEA, or
          support@d2d.am truck.com if in the EEA.
        </p>
        <p>
          - Objections, restrictions, and complaints: Users may request that we
          stop using all or some of their personal data, or that we limit our
          use of their data. door2door and d2d truck Freight may continue to
          process data after such objection or request to the extent required or
          permitted by law.
        </p>
        <p>
          In addition, depending on their location, users may have the right to
          file a complaint relating to door2door and d2d truck Freight’s
          handling of their personal data with the applicable data protection
          authority. Users can find the contact details of the data protection
          authorities in the EEA here. Users in the EEA may also file a
          complaint with the Autoriteit Persoonsgegevens, the Dutch Data
          Protection Authority:
        </p>
        <p>Yerevan Armenia Khanjyan Street 15, 010</p>
        <p>+37412600068</p>
      </div>
    );
  }
}
