import firebase from "firebase";
import "firebase/auth";
import "firebase/database";
import AppBar from "material-ui/AppBar";
import Paper from "material-ui/Paper";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import TextField from "material-ui/TextField";
import React, { Component } from "react";

var ip = require("ip");

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      authMail: "",
      date: new Date(),
      USER: "Client",
    };
  }

  handleLogin() {
    const auth = firebase.auth();
    const promise = auth.signInWithEmailAndPassword(
      this.state.username,
      this.state.password
    );

    promise.catch((e) => alert(e.message));
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        const ISOStringDate =
          this.state.date.toLocaleDateString() +
          " " +
          this.state.date.toLocaleTimeString();
        var row = {
          Email: firebaseUser.email,
          ip: ip.address(),
          time: ISOStringDate,
        };
        firebase.database().ref().child("Log").push().set(row);

        //    this.props.history.push('./Stats');
      }
    });
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        firebase
          .database()
          .ref()
          .child("Users")
          .orderByChild("Email")
          .equalTo(firebaseUser.email)
          .once("child_added", (snap) => {
            this.props.history.push("./Orders");
          });

        //  firebase.database().ref().child('MobileClients').orderByChild("Email").equalTo(firebaseUser.email).once("child_added",snap=>{

        //   this.props.history.push("./ClientSideDisplay");

        //  })
      }
    });
  }

  render() {
    return (
      <div style={{ marginTop: 40 }}>
        <MuiThemeProvider>
          <React.Fragment>
            <img
              src="/logo.png"
              alt="Smiley face"
              style={{ marginLeft: "40%" }}
            />
            <Paper style={{ width: 440, marginLeft: "33%" }} zDepth={5}>
              <AppBar
                title="Login"
                titleStyle={{ lineHeight: 2 }}
                style={{ height: 45, backgroundColor: "#414453" }}
                showMenuIconButton={false}
              />
              <TextField
                id="user"
                hintText="Enter your Username"
                floatingLabelText="Username"
                style={{ marginLeft: "10%" }}
                onChange={(event, newValue) =>
                  this.setState({ username: newValue })
                }
              />
              <br />
              <TextField
                id="password"
                type="password"
                style={{ marginLeft: "10%" }}
                hintText="Enter your Password"
                floatingLabelText="Password"
                onChange={(event, newValue) =>
                  this.setState({ password: newValue })
                }
              />
              <br />
              <button
                title="login"
                style={style}
                onClick={this.handleLogin.bind(this)}
              >
                Login{" "}
              </button>
            </Paper>
          </React.Fragment>
        </MuiThemeProvider>
      </div>
    );
  }
}
const style = {
  margin: 15,
  marginLeft: "40%",
  width: 120,
  height: 40,
  backgroundColor: "#EB6832",
  color: "white",
};
export default Login;
