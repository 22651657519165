import React from "react";

export default class  NumberInput  extends React.Component {
    constructor(props){
        super(props);
        this.state={
            number:""
        }
    }
    componentDidMount(){
       if (this.props.trucks)
        this.setState({number:this.props.trucks[0].id});
   }
    sendOrder=()=>{
        this.props.sendOrder(this.state.number)
    }
    render(){
        return(
            <div style={{position:"absolute" , top:"30%",left:"30%",height:120,width:320,backgroundColor:"grey",padding:40}}>
            <input  value={this.state.number}  onChange= {(e)=>this.setState({number:e.target.value}) } />
            <select id="cars" value={this.state.number} onChange={(e)=>{
               this.props.setNumber(e.target.value);
                 this.setState({number:e.target.value});
                 
                  }
                  }>
               {
                   this.props.trucks.map(a=>{
                       return(
                       <option value={a.id}>{a.name}</option>
                       )
                   })
               }
                </select>
            <button onClick={this.sendOrder}>Send</button>
            <button onClick={this.props.close}>close</button>
             </div>
        )
    }
}