import React, { Component } from "react";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

import AppBar from "material-ui/AppBar";
import Button from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import firebase from "firebase/app";
import Drawer from "material-ui/Drawer";
import MenuItem from "material-ui/MenuItem";
import ClientIcon from "material-ui/svg-icons/action/account-box";
import AgentIcon from "material-ui/svg-icons/maps/local-shipping";
import AccessIcon from "material-ui/svg-icons/social/people";
import LogIcon from "material-ui/svg-icons/device/storage";
import UsersIcon from "material-ui/svg-icons/social/person-add";
import StatsIcon from "material-ui/svg-icons/action/assessment";
import { List, ListItem } from "material-ui/List";
import CommunicationChatBubble from "material-ui/svg-icons/communication/chat-bubble";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { withRouter } from "react-router-dom";
import IconButton from "material-ui/IconButton";
import Badge from "material-ui/Badge";
import { Tabs, Tab } from "material-ui/Tabs";
import SwipeableViews from "react-swipeable-views";
import sound from "./notifi.mp3";
import { inject } from "mobx-react";

@inject("ordersStore")
class HaderSide extends Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);

    this.clientsButton = this.clientsButton.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.getTotalBadge = this.getTotalBadge.bind(this);
    this.aaa = new Audio(sound);

    this.state = {
      icon: true,
      Access: false,
      width: 250,
      FirstTime: false,
      Client: false,
      Users: [],
      chatList: true,
      newMessageRow: {},
      messegesArray: [],
      chatClient: "Conversations",
      slideIndex: 0,
      Clients: [],
      UserName: "",
      Voicer: "",
    };
    this.trucks = [];
  }

  componentWillReceiveProps() {
    let User = "";

    if (!firebase.auth().currentUser) {
      this.setState({ icon: false });
    } else {
      this.setState({ icon: true, UserName: this.props.user });
      User = firebase.auth().currentUser.email;
      let Users = this.state.Users;
      this.setState({ User: User });

      let userIndex = this.state.Users.findIndex((todo) => todo.mail === User);
      if (userIndex !== -1) {
        Users.splice(userIndex, 1);
        this.setState({ Users: Users });
      }
    }

    if (!firebase.auth().currentUser.email.includes("operator")) {
      this.setState({ Access: true });
    } else {
      this.setState({ Access: false });
    }

    firebase
      .database()
      .ref()
      .child("MobileClients")
      .orderByChild("Email")
      .equalTo(User)
      .on("child_added", (snap) => {
        this.setState({ Client: true });
      });
    let tempUsers = [];

    firebase
      .database()
      .ref()
      .child("Users")
      .on("child_added", (snap) => {
        const response = snap.val();

        if (response.Email !== User) {
          let row = {
            mail: response.Email,
            name: response.Name,
            badge: 0,
          };
          tempUsers.push(row);
        }
      });

    //if(this.props.Access!==undefined)
    //{
    //    this.state.Access=true;
    // }
    //else
    // this.state.Access=false;
  }
  async componentDidMount() {
    const tempClients = [];
    let i = 0;

    firebase
      .database()
      .ref()
      .child("Voice")
      .child("toD2D")
      .on("child_changed", async (snap) => {
        const path = await firebase
          .storage()
          .ref()
          .child(snap.key)
          .getDownloadURL();
        let sss = new Audio(path);
        sss.play();
        let filterTrucks = this.state.Clients.filter((a) => a.type === "truck");
        let index = filterTrucks.findIndex((a) => a.name === snap.key);

        this.setState({ Voicer: filterTrucks[index].realName });

        sss.onended = () => {
          firebase.database().ref().child("Voice").child(snap.key).set(null);
          this.setState({ Voicer: "" });
        };
      });

    firebase
      .database()
      .ref()
      .child("ClientsData")
      .once("value", (snap1) => {
        this.clientsCount = snap1.numChildren();
        firebase
          .database()
          .ref()
          .child("ClientsData")
          .on("child_added", (snap) => {
            const response = snap.val();
            i++;
            let row = {
              name: snap.key,
              mail: response.Email,
              badge: 0,
            };
            tempClients.push(row);
            if (i === this.clientsCount)
              this.setState({ Clients: tempClients });
          });
      });
    let f = 0;
    firebase
      .database()
      .ref()
      .child("RegisteredTrucks")
      .once("value", (snap2) => {
        this.trucksCount = snap2.numChildren();
        firebase
          .database()
          .ref()
          .child("RegisteredTrucks")
          .on("child_added", (snap) => {
            f++;
            const response = snap.val();
            let row = {
              name: snap.key,
              mail: response.Email,
              badge: 0,
              type: "truck",
              realName: response.name + " " + response.type,
              truckType: response.type,
            };
            tempClients.push(row);

            this.trucks.push(snap.key);
            // this.props.ordersStore.allTruck=tempClients.filter(a=>a.type==="truck");

            if (this.trucksCount === f) {
              this.setState({ Clients: tempClients });
            }
            //  if (f===this.trucksCount){
            //   this.setState({ Clients: tempClients });
            //  }
          });
      });

    this.Clients = tempClients;

    firebase
      .database()
      .ref()
      .child("messeges")
      .orderByChild("chatClient")
      .equalTo("d2d")
      .limitToLast(1)
      .on("child_added", (snap) => {
        const response = snap.val();
        for (let i = 0; i < tempClients.length; i++) {
          if (
            tempClients[i].name === response.sender &&
            response.status === "unread" &&
            response.sender !== "d2d"
          ) {
            this.aaa.play();

            tempClients[i].badge++;

            this.setState({ Clients: tempClients });
          }
        }
      });
  }
  getTotalBadge() {
    let badge = 0;
    for (let i = 0; i < this.state.Clients.length; i++) {
      badge += this.state.Clients[i].badge;
    }
    return badge;
  }

  handleToggle = () => {
    if (this.state.Client) return;
    else this.setState({ open: !this.state.open });
  };
  handleClose = () => this.setState({ open: false, UserName: "door2door" });
  clientsButton() {
    this.handleClose();
    this.props.history.push("/ClientsData");
  }
  statsButton() {
    this.handleClose();
    this.props.history.push("/ActiveOrders");
  }
  ordersButton() {
    this.handleClose();
    this.props.history.push("/Orders");
  }
  mapButton() {
    this.handleClose();
    this.props.history.push("/Map");
  }
  agentsButton() {
    this.handleClose();
    this.props.history.push("/AgentsDisplay");
  }
  regTrucksButton() {
    this.handleClose();
    this.props.history.push("./RegisteredTrucks");
  }
  trucksButton() {
    this.handleClose();
    this.props.history.push("./Trucks");
  }
  usersButton() {
    this.handleClose();
    this.props.history.push("./ClientsData");
  }

  Logout() {
    this.handleClose();
    firebase.auth().signOut();
  }
  LogButton() {
    this.handleClose();
    this.props.history.push("./UsersLogDisplay");
  }
  AccessButton() {
    this.handleClose();
    this.props.history.push("./AccessDisplay");
  }

  handleInput(e) {
    this.setState({ newMessage: e.target.value });
  }
  checkEnter(e) {
    if (e.keyCode === 13) {
      let row = {
        newMessage: this.state.newMessage,
        sender: "d2d",
        date:
          new Date().toLocaleDateString() +
          "\n" +
          new Date().toLocaleTimeString(),
        chatClient: this.state.chatClient,
        status: "unread",
      };
      this.setState({ newMessageRow: row });
      this.setState({ newMessage: "" });
      firebase.database().ref().child("messeges").push().set(row);
      this.sendNotification(row.newMessage, this.fcm);
    } else return;
  }
  hadleChatName(e) {
    let tempMesseges = [];
    let row = {};
    this.setState({ chatList: false });
    this.setState({ chatClient: e.target.innerText });
    let chatClient = e.target.innerText;
    let temp = this.state.Users;

    this.setState({ Users: temp });
    firebase
      .database()
      .ref()
      .child("messeges")
      .orderByChild("chatClient")
      .equalTo(chatClient)
      .on("child_added", (snap) => {
        const response = snap.val();

        if (
          (response.chatClient === chatClient && response.sender === "d2d") ||
          (response.chatClient === "d2d" && response.sender === chatClient)
        ) {
          row = response;
          row.id = snap.key;
          row.compareDate = new Date(row.date).getTime() / 1000;
          tempMesseges.push(row);
        }

        this.setState({ messegesArray: tempMesseges });
      });
    firebase
      .database()
      .ref()
      .child("messeges")
      .orderByChild("sender")
      .equalTo(chatClient)
      .on("child_added", (snap) => {
        const response = snap.val();

        if (
          (response.chatClient === chatClient && response.sender === "d2d") ||
          (response.chatClient === "d2d" && response.sender === chatClient)
        ) {
          row = response;
          row.id = snap.key;
          row.date = row.date.replace("\n", " ");
          row.date = row.date.replace(".", "-");
          row.date = row.date.replace(".", "-");

          row.compareDate = new Date(row.date).getTime() / 1000;

          tempMesseges.push(row);
        }

        this.setState({ messegesArray: tempMesseges });
      });
    firebase
      .database()
      .ref()
      .child("ClientsData")
      .child(chatClient)
      .child("fcm")
      .once("value", (snap) => {
        if (!snap.exists()) {
          firebase
            .database()
            .ref()
            .child("RegisteredTrucks")
            .child(chatClient)
            .child("fcm")
            .once("value", (s) => {
              this.fcm = s.val();
            });
          return;
        }
        this.fcm = snap.val();
      });
    const { Clients } = this.state;
    Clients.filter((a) => a.name === chatClient)[0].badge = 0;

    this.setState({ Clients });
  }
  componentDidUpdate() {
    if (!this.state.chatList) {
      var el = this.refs.wrap;
      el.scrollTop = el.scrollHeight;
    }
  }
  handleChange = (value) => {
    this.setState({
      slideIndex: value,
    });
  };
  backList() {
    this.setState({ chatList: true, chatClient: "Conversations" });
    var el = this.refs.wrap;
    el.scrollTop = 0;
  }

  sendNotification = (massage, to) => {
    fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers: {
        Authorization:
          "key=AAAAlJYf2Tc:APA91bFc01tEi8LFLwYubqTv1NAlbFHW159LJnDeQt3jfaD2eyJEIm-mx8dniizpCVt1nIlWaHmcyLJ-ootL6z5CwyzC7dOMJcU8pqaDwDIJ4T5qKTmgHSU4X6WUaxkr8QjKTSbXeb6b",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: to,
        notification: {
          body: massage,
          title: "door2door",
          sound: "default",
        },
        data: {
          orderId: "chat",
        },
      }),
    });
    if (to === "/topics/AllTrucks") {
      let row = {
        newMessage: massage,
        sender: "d2d",
        date:
          new Date().toLocaleDateString() +
          "\n" +
          new Date().toLocaleTimeString(),
        chatClient: "all",
        status: "unread",
      };
      firebase.database().ref().child("messeges").push().set(row);
    }
  };

  usersMasButton = () => {
    this.reciever = "/topics/all";
    this.setState({ mass: true });
  };
  truckMasButton = () => {
    // this.reciever="/topics/AllTrucks";
    this.reciever = "/topics/AllTrucks";
    this.setState({ mass: true });
  };
  storeCategories = () => {
    // this.reciever="/topics/AllTrucks";
    this.handleClose();
    this.props.history.push("./StoreCategories");
  };

  render() {
    let style = {};
    let clients = this.state.Clients;

    clients.sort((a, b) => {
      return b.badge - a.badge;
    });
    if (this.state.chatSearch) {
      clients = this.state.Clients.filter((a) =>
        a.name.includes(this.state.chatSearch)
      );
    }
    return (
      <MuiThemeProvider>
        <React.Fragment>
          <div style={{ backgroundColor: "red", color: "white" }}>
            {this.state.Voicer}
          </div>
          {this.state.mass && (
            <div
              style={{
                position: "absolute",
                right: 120,
                top: 140,
                height: "40%",
                width: "40%",
                backgroundColor: "grey",
                zIndex: 999,
              }}
            >
              <div>{this.reciever}</div>
              title
              <input
                style={{ margin: 20 }}
                value={this.state.title}
                onChange={(e) => this.setState({ title: e.innerText })}
              />
              <div style={{ margin: 20, marginLeft: 0 }}>
                Massege
                <textarea
                  value={this.state.body}
                  onChange={(e) => {
                    this.setState({ body: e.target.value });
                  }}
                />
              </div>
              <label for="cars">Choose a car:</label>
              <select
                id="cars"
                value={this.state.truckType}
                onChange={(e) => {
                  alert(e.target.value);
                  this.setState({ truckType: e.target.value });
                }}
              >
                <option value="/topics/all">All users</option>
                <option value="/topics/AllTrucks">All Trucks</option>
                <option value="halftone">halftone</option>
                <option value="ford">ford</option>
                <option value="gazele">gazele</option>
                <option value="tonnik">tonnik</option>
                <option value="tentones">tentones</option>
                <option value="pickup">pickup</option>
              </select>
              <div>
                <input
                  type="button"
                  value="send"
                  style={{ padding: 10, marginLeft: 20 }}
                  onClick={() => {
                    this.sendNotification(
                      this.state.body,
                      this.state.truckType
                    );
                    this.setState({ body: "" });
                  }}
                />
                <input
                  type="button"
                  value="close"
                  style={{ padding: 10, marginLeft: 20 }}
                  onClick={() => {
                    this.setState({ body: "", mass: false });
                  }}
                />
              </div>
            </div>
          )}
          <React.Fragment>
            <AppBar
              style={{
                position: "fixed",
                zIndex: 1,
                height: 60,
                backgroundColor: "#414453",
              }}
              showMenuIconButton={this.state.icon}
              onLeftIconButtonClick={this.handleToggle}
              title={
                <div>
                  {this.getTotalBadge() > 0 && (
                    <Badge
                      badgeStyle={{ left: 0 }}
                      badgeContent={this.getTotalBadge()}
                      secondary={true}
                    />
                  )}
                  <img src="/chatLogo1.png" alt="" />
                  {" " + this.state.UserName.toUpperCase()}
                </div>
              }
            >
              {this.state.icon && (
                <div>
                  {!this.state.Client && (
                    <div style={{ display: "inline-block" }}>
                      <Button
                        label="Map"
                        backgroundColor="#EB6832"
                        labelColor="white"
                        style={{ marginTop: 14, marginLeft: 4 }}
                        onClick={this.mapButton.bind(this)}
                      />
                      <Button
                        label="Orders"
                        backgroundColor="#EB6832"
                        labelColor="white"
                        style={{ marginTop: 14, marginLeft: 4 }}
                        onClick={this.ordersButton.bind(this)}
                      />
                      <Button
                        label="Loadings"
                        backgroundColor="#EB6832"
                        labelColor="white"
                        style={{ marginTop: 14, marginLeft: 10 }}
                        onClick={this.trucksButton.bind(this)}
                      />
                    </div>
                  )}
                  <FlatButton
                    label="Log Out"
                    style={{ color: "white", marginTop: 14 }}
                    onClick={this.Logout.bind(this)}
                  />
                </div>
              )}
            </AppBar>
            <Drawer
              docked={false}
              width={this.state.width}
              open={this.state.open}
              onRequestChange={(open) => this.setState({ open })}
            >
              <AppBar
                title="Menu"
                showMenuIconButton={false}
                style={{ height: 60, backgroundColor: "#414453" }}
              />

              <div
                style={{
                  backgroundColor: "white",
                  height: "40%  ",
                  overflow: "scroll",
                }}
              >
                {this.state.Access && (
                  <MenuItem onClick={this.regTrucksButton.bind(this)}>
                    <AgentIcon
                      style={{
                        marginRight: 10,
                        marginBottom: 0,
                        position: "relative",
                        top: 5,
                        color: "#EB6832",
                      }}
                    />
                    Registerd Trucks
                  </MenuItem>
                )}
                <MenuItem onClick={this.agentsButton.bind(this)}>
                  <AgentIcon
                    style={{
                      marginRight: 10,
                      marginBottom: 0,
                      position: "relative",
                      top: 5,
                      color: "#EB6832",
                    }}
                  />
                  Agents
                </MenuItem>
                <MenuItem onClick={this.clientsButton}>
                  <ClientIcon
                    style={{
                      marginRight: 10,
                      marginBottom: 0,
                      position: "relative",
                      top: 5,
                      color: "#EB6832",
                    }}
                  />
                  Clients
                </MenuItem>
                <MenuItem onClick={this.statsButton.bind(this)}>
                  <StatsIcon
                    style={{
                      marginRight: 10,
                      marginBottom: 0,
                      position: "relative",
                      top: 5,
                      color: "#EB6832",
                    }}
                  />
                  ActiveOrders
                </MenuItem>

                {(this.state.Access ||
                  this.state.User === "karen.soghoyan@d2d.am") && (
                  <div>
                    <MenuItem onClick={this.AccessButton.bind(this)}>
                      <AccessIcon
                        style={{
                          marginRight: 10,
                          marginBottom: 0,
                          position: "relative",
                          top: 5,
                          color: "#EB6832",
                        }}
                      />
                      Access
                    </MenuItem>

                    {this.state.User === "karen.soghoyan@d2d.am" && (
                      <div style={{ display: "inline" }}>
                        <MenuItem onClick={this.LogButton.bind(this)}>
                          <LogIcon
                            style={{
                              marginRight: 10,
                              marginBottom: 0,
                              position: "relative",
                              top: 5,
                              color: "#EB6832",
                            }}
                          />
                          Log...
                        </MenuItem>
                        <MenuItem onClick={this.usersButton.bind(this)}>
                          <UsersIcon
                            style={{
                              marginRight: 10,
                              marginBottom: 0,
                              position: "relative",
                              top: 5,
                              color: "#EB6832",
                            }}
                          />
                          Users
                        </MenuItem>
                        <MenuItem onClick={this.usersMasButton.bind(this)}>
                          <UsersIcon
                            style={{
                              marginRight: 10,
                              marginBottom: 0,
                              position: "relative",
                              top: 5,
                              color: "#EB6832",
                            }}
                          />
                          Message To all users
                        </MenuItem>
                        <MenuItem onClick={this.truckMasButton.bind(this)}>
                          <UsersIcon
                            style={{
                              marginRight: 10,
                              marginBottom: 0,
                              position: "relative",
                              top: 5,
                              color: "#EB6832",
                            }}
                          />
                          Message To all Trucks
                        </MenuItem>
                        <MenuItem onClick={this.storeCategories.bind(this)}>
                          <UsersIcon
                            style={{
                              marginRight: 10,
                              marginBottom: 0,
                              position: "relative",
                              top: 5,
                              color: "#EB6832",
                            }}
                          />
                          Store Categories
                        </MenuItem>
                      </div>
                    )}
                  </div>
                )}
                <div
                  ref="wrap"
                  style={{
                    overflowY: "scroll",
                    textAlign: "center",
                    marginLeft: 0,
                    marginTop: 23,
                    height: "43%",
                    width: "98%",
                    borderBottomWidth: 1,
                    borderBottomColor: "black",
                    borderStyle: "ridge",
                    position: "absolute",
                    bottom: 5,
                    backgroundColor: "#ECEFF1",
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      position: "fixed",
                      backgroundColor: "#414453",
                      padding: 2,
                      marginBottom: 2,
                      width: "96%",
                      textAlign: "center",
                      borderBottomWidth: 1,
                      borderBottomColor: "black",
                      borderStyle: "ridge",
                      left: 2,
                      top: "52%",
                      color: "white",
                    }}
                  >
                    {!this.state.chatList && (
                      <IconButton
                        style={{
                          padding: 0,
                          margin: 0,
                          height: 20,
                          position: "absolute",
                          left: 4,
                          color: "white",
                        }}
                        onClick={this.backList.bind(this)}
                      >
                        <CommunicationChatBubble />
                      </IconButton>
                    )}
                    {this.state.chatClient.split("@")[0]}
                  </div>
                  <input
                    placeholder={"search"}
                    value={this.state.chatSearch || ""}
                    onChange={(e) => {
                      this.setState({ chatSearch: e.target.value });
                    }}
                  />
                  {!this.state.chatList && (
                    <div>
                      <List
                        style={{ marginBottom: 40, wordWrap: "break-word" }}
                      >
                        {this.state.messegesArray
                          .sort((a, b) => a.compareDate - b.compareDate)
                          .map((value) => {
                            if (value.sender === "d2d") {
                              style = {
                                backgroundColor: "#2196F3",
                                color: "white",
                                borderBottomColor: "cyan",
                                width: "90%",
                                marginBottom: 2,
                                fontSize: 14,
                                borderRadius: 3,
                              };
                            } else {
                              if (value.status === "unread") {
                                firebase
                                  .database()
                                  .ref()
                                  .child("messeges")
                                  .child(value.id)
                                  .child("status")
                                  .set("read");
                                value.status = "read";
                              }
                              style = {
                                backgroundColor: "#3F51B5",
                                color: "white",
                                borderBottomColor: "cyan",
                                width: "90%",
                                marginBottom: 2,
                                fontSize: 14,
                                marginLeft: 18,
                                borderRadius: 3,
                              };
                            }
                            return (
                              <ListItem
                                style={style}
                                primaryText={value.newMessage}
                                secondaryText={value.date}
                              >
                                <span
                                  style={{
                                    position: "absolute",
                                    bottom: 0,
                                    right: 2,
                                    color: "#FFFDE7",
                                    fontSize: 9,
                                  }}
                                >
                                  {value.status}
                                </span>
                              </ListItem>
                            );
                          })}
                      </List>
                      <input
                        style={{
                          left: 5,
                          position: "fixed",
                          bottom: 0,
                          marginBottom: 7,
                          width: "94%",
                        }}
                        placeholder="New Message"
                        value={this.state.newMessage}
                        onKeyDown={this.checkEnter.bind(this)}
                        onChange={(e) => this.handleInput(e)}
                      />
                    </div>
                  )}
                  {this.state.chatList && (
                    <div>
                      <Tabs
                        onChange={this.handleChange}
                        value={this.state.slideIndex}
                        style={{ backgroundColor: "red" }}
                      >
                        <Tab
                          label="Trucks"
                          value={0}
                          style={{
                            fontSize: 12,
                            height: 20,
                            backgroundColor: "#414453",
                          }}
                          buttonStyle={{ height: 2 }}
                        />
                        <Tab
                          label="Clients"
                          value={1}
                          style={{
                            fontSize: 12,
                            height: 20,
                            backgroundColor: "#414453",
                          }}
                          buttonStyle={{ height: 2 }}
                        />
                      </Tabs>

                      <SwipeableViews
                        index={this.state.slideIndex}
                        onChangeIndex={this.handleChange}
                      >
                        <List>
                          {clients
                            .filter((a) => a.type === "truck")
                            .map((value, index) => {
                              return (
                                <ListItem
                                  key={value + index}
                                  primaryText={
                                    <span
                                      onClick={this.hadleChatName.bind(this)}
                                    >
                                      {" "}
                                      {value.name}
                                    </span>
                                  }
                                  secondaryText={value.realName}
                                  //  onClick={this.hadleChatName.bind(this)}
                                  rightIcon={
                                    value.badge > 0 ? (
                                      <Badge
                                        badgeContent={value.badge}
                                        secondary={true}
                                        badgeStyle={{ top: 0, right: 10 }}
                                      />
                                    ) : (
                                      <span></span>
                                    )
                                  }
                                />
                              );
                            })}
                        </List>
                        <List>
                          {clients
                            .filter((a) => a.type !== "truck")
                            .map((value, index) => {
                              return (
                                <ListItem
                                  key={value + index}
                                  primaryText={
                                    <span
                                      onClick={this.hadleChatName.bind(this)}
                                    >
                                      {" "}
                                      {value.name}
                                    </span>
                                  }
                                  secondaryText={value.name}
                                  rightIcon={
                                    value.badge > 0 ? (
                                      <Badge
                                        badgeContent={value.badge}
                                        secondary={true}
                                        badgeStyle={{ top: 0, right: 10 }}
                                      />
                                    ) : (
                                      <div></div>
                                    )
                                  }
                                />
                              );
                            })}
                        </List>
                      </SwipeableViews>
                    </div>
                  )}
                </div>
              </div>
            </Drawer>

            <Button label="Open Drawer" onClick={this.handleToggle} />
          </React.Fragment>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}
export default withRouter(HaderSide);
