import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import AppBar from "material-ui/AppBar";
import Paper from "material-ui/Paper";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

class ActiveOrders extends Component {
  constructor(props) {
    super(props);
    this.databaseRef = firebase.database().ref().child("ActiveOrders");

    this.state = {
      Access: false,
      Agents: [],
      tempRows: [],
      index: "",
      jobTypes: [
        "halftone",
        "ford",
        "gazele",
        "tonnik",
        "tentones",
        "pickup",
        "express",
      ],
      ShowTypes: false,
      blankContact: [],
      tableWidth: 430,
    };
  }

  componentDidMount() {
    let User = firebase.auth().currentUser.email;
    this.setState({ tableWidth: window.innerHeight * 0.7 });
    firebase
      .database()
      .ref()
      .child("Users")
      .orderByChild("Email")
      .equalTo(User)
      .on("child_added", (snap) => {
        const response = snap.val();
        if (response.Access !== undefined) {
          this.setState({ Access: true });
        } else {
          this.setState({ Access: true });
        }
      });

    this.databaseRef.on("value", (snap) => {
      if (!snap.exists()) return;
      const response = snap.val();

      const temp = this.state.Agents;
      Object.keys(response).forEach((a) => {
        const index = temp.findIndex((b) => b.orderId === response[a].orderId);

        if (index === -1) {
          temp.push(response[a]);
        } else {
          temp[index] = response[a];
        }
      });
      this.setState({ Agents: temp });
    });
    this.databaseRef.on("child_removed", (snap) => {
      const temp = this.state.Agents;
      const index = temp.findIndex((a) => (a.orderId = snap.val().orderId));
      if (index !== -1) {
        temp.splice(index, 1);
      }
      this.setState({ Agents: temp });
    });
  }

  onRowSelect = (row, isSelected, e) => {
    this.setState({ index: row.id });
  };

  onAfterDeleteRow(rowKeys) {
    firebase.database().ref().child("ActiveOrders").child(rowKeys[0]).remove();
  }

  render() {
    const selectRowProp = {
      mode: "radio",
      clickToSelectAndEditCell: true,
      onSelect: this.onRowSelect,
    };

    const cellEditProp = {
      mode: "dbclick",
      blurToSave: true,
    };

    return (
      <MuiThemeProvider>
        <Paper style={{ marginTop: 40 }}>
          <AppBar
            title="Reg Trucks"
            showMenuIconButton={false}
            titleStyle={{ fontSize: 16, lineHeight: 3 }}
            style={{ zIndex: 0, height: 45, backgroundColor: "#414453" }}
          />
          <br />
          <br />
          <BootstrapTable
            striped
            hover
            version="4"
            insertRow={this.state.Access}
            data={this.state.Agents}
            remote={this.remote}
            cellEdit={cellEditProp}
            selectRow={selectRowProp}
            deleteRow={true}
            height={this.state.tableWidth}
            search
          >
            <TableHeaderColumn dataField="clientId" width={120} hiddenOnInsert>
              Client ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="truck"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              truck
            </TableHeaderColumn>
            <TableHeaderColumn
              isKey
              dataField="orderId"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              orderId
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="address"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              address
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="unloadingAddress"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              unloadingAddress
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="type"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              type
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="to"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              to
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="payment"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              payment
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="urgent"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              urgent
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="comment"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              comment
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="withReturn"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              withReturn
            </TableHeaderColumn>
          </BootstrapTable>
        </Paper>
      </MuiThemeProvider>
    );
  }
}
export default ActiveOrders;
