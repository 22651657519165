import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import AppBar from "material-ui/AppBar";
import Paper from "material-ui/Paper";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

class RegisteredTrucks extends Component {
  constructor(props) {
    super(props);
    this.databaseRef = firebase.database().ref().child("RegisteredTrucks");
    this.customNameField = this.customNameField.bind(this);
    this.change = this.change.bind(this);
    this.onToggleRegion = this.onToggleRegion.bind(this);
    this.addPost = this.addPost.bind(this);
    this.onAddRow = this.onAddRow.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.upDatePost = this.upDatePost.bind(this);
    this.onAfterSaveCell = this.onAfterSaveCell.bind(this);

    this.getFieldValue = this.getFieldValue.bind(this);
    this.state = {
      Access: false,
      Agents: [],
      tempRows: [],
      index: "",
      jobTypes: [
        "halftone",
        "ford",
        "gazele",
        "tonnik",
        "tentones",
        "pickup",
        "express",
      ],
      ShowTypes: false,
      blankContact: [],
      tableWidth: 430,
    };
  }

  componentDidMount() {
    let User = firebase.auth().currentUser.email;
    this.setState({ tableWidth: window.innerHeight * 0.7 });
    firebase
      .database()
      .ref()
      .child("Users")
      .orderByChild("Email")
      .equalTo(User)
      .on("child_added", (snap) => {
        const response = snap.val();
        if (response.Access !== undefined) {
          this.setState({ Access: true });
        } else {
          this.setState({ Access: true });
        }
      });

    this.databaseRef.once("value", (snap) => {
      const response = snap.val();

      const temp = [];
      Object.keys(response).forEach((a) => {
        temp.push(response[a]);
      });
      this.setState({ Agents: temp });
    });
  }

  addPost(Company) {
    const postToSave = { Company };
    postToSave.Company.id = postToSave.Company.phone;
    postToSave.Company.minimal = parseFloat(postToSave.Company.minimal);
    this.databaseRef.child(postToSave.Company.phone).set(postToSave.Company);
  }
  change(e) {
    if (e.target.value === "Forwarder") {
      this.setState({ ShowTypes: true });
    } else {
      this.setState({ ShowTypes: false });
    }
  }
  customNameField = (column, attr, editorClass, ignoreEditable) => {
    return (
      <select onChange={this.change} className={`${editorClass}`} {...attr}>
        {this.state.jobTypes.map((name) => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </select>
    );
  };

  onToggleRegion(event) {
    const region = event.currentTarget.name;
    if (this.state.fruits.indexOf(region) < 0) {
      this.setState({ fruits: this.state.fruits.concat([region]) });
    } else {
      this.setState({ fruits: this.state.fruits.filter((r) => r !== region) });
    }
  }
  onAddRow(row) {
    //this.state.testrow.push(row);
    this.addPost(row);
  }
  remote(remoteObj) {
    // Only cell editing, insert and delete row will be handled by remote store
    remoteObj.cellEdit = true;
    remoteObj.insertRow = true;
    remoteObj.dropRow = true;
    return remoteObj;
  }

  getFieldValue = () => {
    return this.state.fruits;
  };

  onRowSelect(row, isSelected, e) {
    this.setState({ index: row.id });
  }

  customTracksField = (column, attr, editorClass, ignoreEditable) => {
    return (
      <select onChange={this.change} className={`${editorClass}`} {...attr}>
        {this.state.jobTypes.map((name) => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </select>
    );
  };
  onCellEdit = async (row, fieldName, value) => {
    if (!this.state.Access) {
      alert("Dont Have Permission");
      return;
    }
    const { Agents } = this.state;
    let rowIdx;
    const targetRow = Agents.find((prod, i) => {
      if (prod.id === row.id) {
        rowIdx = i;

        return true;
      } else {
        return false;
      }
    });
    if (targetRow) {
      if (fieldName === "balance") {
        let change = Number(value) - Number(targetRow[fieldName]);
        await firebase
          .database()
          .ref()
          .child("BalanceLog")
          .child(targetRow.id)
          .push({
            oldValue: targetRow[fieldName],
            newValue: value,
            change: change,
            time: new Date().toString(),
          });
      }
      targetRow[fieldName] = value;
      Agents[rowIdx] = targetRow;
      this.setState({ Agents });
      this.forceUpdate();
    }
    if (fieldName === "minimal") {
      value = +value;
    }
    this.databaseRef.child(row.id).child(fieldName).set(value);
  };

  upDatePost(row) {
    console.log(this.state.index);

    let index = this.state.Agents.findIndex(
      (todo) => todo.id === this.state.index
    );
    row.id = this.state.Agents[index].subcomponent.length;
    const postToSave = { row };

    this.state.Agents[index].subcomponent.push(postToSave.row);
    this.databaseRef
      .child(this.state.index)
      .child("contactPerson")
      .set(this.state.Agents[index].subcomponent);
    this.forceUpdate();
  }

  onAfterInsertSubcomponent(row) {
    let newRowStr = "";

    for (const prop in row) {
      newRowStr += prop + ": " + row[prop] + " \n";
    }
    alert("The new row is:\n " + newRowStr);

    this.state.testrow[0].subcomponent.push(newRowStr);
  }

  onAfterSaveCell(row, fieldName, value) {
    if (!this.state.Access) {
      alert("Dont have permission");
      return;
    }
    let index = this.state.Agents.findIndex(
      (todo) => todo.id === this.state.index
    );

    const data = this.state.Agents;
    data[index].subcomponent[row.id] = row;
    this.setState({ agents: data });
    this.databaseRef
      .child(this.state.index)
      .child("contactPerson")
      .child(row.id)
      .child(fieldName)
      .set(value);
  }
  createCustomModalHeader = (closeModal, save) => {
    const headerStyle = {
      fontWeight: "bold",
      fontSize: "medium",
      textAlign: "center",
      backgroundColor: "#00BCD4",
      color: "white",
    };
    return (
      <div className="modal-header" style={headerStyle}>
        <h3>New Agent</h3>
      </div>
    );

    // If you want have more power to custom the child of InsertModalHeader,
    // you can do it like following
    // return (
    //   <InsertModalHeader
    //     beforeClose={ this.beforeClose }
    //     onModalClose={ () => this.handleModalClose(closeModal) }>
    //     { ... }
    //   </InsertModalHeader>
    // );
  };
  onUploadStart = () => {};
  createCustomShowSelectButton = (onClick, showSelected) => {
    return (
      <button style={{ color: "red" }}>
        {showSelected ? "Select Only" : "All"}
      </button>
    );
  };
  onFileSelect = (e) => {
    const file = e.target.files[0];
    firebase.storage().ref().child("Cards").child("aaa").put(file);
  };
  onBeforeSaveCell = (row, cellName, cellValue) => {
    alert(cellName);
  };
  render() {
    const options = {
      onAddRow: this.onAddRow,
      insertModalHeader: this.createCustomModalHeader,
      onCellEdit: this.onCellEdit,
      showSelectedOnlyBtn: this.createCustomShowSelectButton,
    };

    const selectRowProp = {
      mode: "radio",
      clickToSelectAndEditCell: true,
      onSelect: this.onRowSelect,
    };

    const cellEditProp = {
      mode: "dbclick",
      blurToSave: true,
    };

    return (
      <MuiThemeProvider>
        <Paper style={{ marginTop: 40 }}>
          <AppBar
            title="Reg Trucks"
            showMenuIconButton={false}
            titleStyle={{ fontSize: 16, lineHeight: 3 }}
            style={{ zIndex: 0, height: 45, backgroundColor: "#414453" }}
          />
          <input type="file" title="card" onChange={this.onFileSelect} />
          <br />
          <BootstrapTable
            striped
            hover
            version="4"
            options={options}
            insertRow={this.state.Access}
            data={this.state.Agents}
            remote={this.remote}
            cellEdit={cellEditProp}
            selectRow={selectRowProp}
            height={this.state.tableWidth}
            search
          >
            <TableHeaderColumn
              dataField="percent"
              editable={true}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={40}
            >
              Percent
            </TableHeaderColumn>
            <TableHeaderColumn isKey dataField="id" width={120} hiddenOnInsert>
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="name"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              Name
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="surname"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              Surname
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="balance"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              Balance
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="plate"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              Plate
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="type"
              editable={{
                type: "select",
                options: { values: this.state.jobTypes },
              }}
              customInsertEditor={{ getElement: this.customNameField }}
            >
              Type
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="phone"
              width={120}
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
            >
              Phone
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="hPhone"
              width={120}
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
            >
              HPhone
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="address"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              Address
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="passport"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              Passport
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="driverLicense"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              Driver License
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="truckLicense"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
            >
              Truck Locense
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="model"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              Model
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="tarif"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              Tariff
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="minimal"
              editable={{ type: "number", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              Minimal
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="fcm"
              editable={false}
              hiddenOnInsert
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              fcm
            </TableHeaderColumn>
            <TableHeaderColumn dataField="Button" hiddenOnInsert>
              Card
            </TableHeaderColumn>
          </BootstrapTable>
        </Paper>
      </MuiThemeProvider>
    );
  }
}
export default RegisteredTrucks;
