import { Component } from "react";

export default class goToMobileApp extends Component {
  componentDidMount() {
    const isSafari = this.getOperatingSystem(window) === "iOS";
    const isAndroid = this.getOperatingSystem(window) === "Android";
    if (isSafari) {
      window.location.assign("https://apps.apple.com/app/id1489048852");
      window.open("https://apps.apple.com/app/id1489048852");
    }
    if (isAndroid) {
      window.location.assign(
        "https://play.google.com/store/apps/details?id=d2d.am"
      );
      window.open(
        "https://play.google.com/store/apps/details?id=d2d.am",
        "_target"
      );
    }
  }
  getOperatingSystem = (window) => {
    let operatingSystem = "Not known";

    if (window.navigator.appVersion.indexOf("iPhone") !== -1) {
      operatingSystem = "iOS";
    }
    if (window.navigator.appVersion.indexOf("Android") !== -1) {
      operatingSystem = "Android";
    }
    return operatingSystem;
  };
  render() {
    return null;
  }
}
