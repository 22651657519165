import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import React from "react";

export default class CustomAlert extends React.Component {
  state = {
    open: true,
  };
  componentDidMount() {
    this.setState({ open: this.props.open });
  }
  handleClose = () => {
    this.props.closeDialog();
    this.setState({ open: false });
  };

  render() {
    const actions = [
      <FlatButton label="Discard" primary={true} onClick={this.handleClose} />,
    ];

    return (
      <div>
        <Dialog
          actions={actions}
          modal={false}
          open={this.props.open}
          onRequestClose={this.props.closeDialog}
        >
          {this.props.alertsms}
        </Dialog>
      </div>
    );
  }
}
