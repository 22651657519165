import { observable, action, computed } from "mobx";
//import * as emailjs from "emailjs-com";
import firebase from "firebase/app";
import { removeUndefined } from "../components/utils/helpers";
import * as geolib from "geolib";
import "firebase/database";
import "firebase/auth";
import { set } from "immutable";

class OrdersStore {
  @observable
  selectedNumber = null;
  @observable
  enabled = "";
  @observable
  isWraped = true;
  @observable
  orderTrucks = {};
  @observable
  showCreateOrder = false;
  @observable
  creating = false;
  @observable
  loaded = false;
  @observable
  access = true;
  @computed
  get toolbarOptions() {
    return this.access
      ? [
          // "Add",

          // { text: "Copy", tooltipText: "Copy", prefixIcon: "e-copy", id: "copy" },
          {
            text: "Wrap",
            tooltipText: "wrap",
            id: "wrap",
          },

          {
            id: "excel",
            template: `${
              this.access
                ? "<button class='btn btn-success btn-xs' title='excel' >excel</button>"
                : ""
            }  `,
          },
          {
            id: "loadingMap",
            template: ` <button class='btn btn-success btn-xs' title="CreateOrder" >LM</button>`,
          },
          {
            id: "unloadingMap",
            template: ` <button class='btn btn-success btn-xs' title="CreateOrder" >UM</button>`,
          },
          {
            id: "co",
            template: ` <button class='btn btn-success btn-xs' title="CreateOrder" >Create Order</button>`,
          },
          {
            id: "quest",
            template: ` <button class='btn btn-info btn-xs' title="quest" ${this.enabled} >Quest.</button>`,
          },
          {
            id: "activate",
            template: ` <button class='btn btn-info btn-xs' title="quest" ${this.enabled} >Activate</button>`,
          },
          {
            id: "cancel",
            template: ` <button class='btn btn-danger btn-xs' title="quest" ${this.enabled} >Cancel</button>`,
          },

          {
            id: "confirm",
            template: ` <button class='btn btn-success btn-xs' title="confirm" >Confirm</button>`,
          },

          // {
          //   id: "reject",
          //   template: ` <button class='btn btn-danger btn-xs' title="confirm" >Reject</button>`
          // },
          // {
          //   id: "archive",
          //   template: ` <button class='btn btn-danger btn-xs' title="archive" >archive</button>`
          // },
          {
            id: "sendOrder",
            template: ` <button class='btn btn-warning btn-xs' title="Send Order" >send order</button>`,
          },
          {
            id: "showAll",
            template: ` <button class='btn btn-success btn-xs' title="Show All" >Show All</button>`,
          },

          {
            id: "700",
            template: ` <button class='btn btn-success btn-xs' title="700" >700</button>`,
          },
          {
            id: "800",
            template: ` <button class='btn btn-warning btn-xs' title="800" >800</button>`,
          },
          {
            id: "900",
            template: ` <button class='btn btn-danger btn-xs' title="900" >1000</button>`,
          },
          "Search",
          "ColumnChooser",
        ]
      : [
          // "Add",

          // { text: "Copy", tooltipText: "Copy", prefixIcon: "e-copy", id: "copy" },
          {
            text: "Wrap",
            tooltipText: "wrap",
            id: "wrap",
          },

          {
            id: "loadingMap",
            template: ` <button class='btn btn-success btn-xs' title="CreateOrder" >LM</button>`,
          },
          {
            id: "unloadingMap",
            template: ` <button class='btn btn-success btn-xs' title="CreateOrder" >UM</button>`,
          },
          {
            id: "co",
            template: ` <button class='btn btn-success btn-xs' title="CreateOrder" >Create Order</button>`,
          },
          {
            id: "quest",
            template: ` <button class='btn btn-info btn-xs' title="quest" ${this.enabled} >Quest.</button>`,
          },

          {
            id: "cancel",
            template: ` <button class='btn btn-danger btn-xs' title="quest" ${this.enabled} >Cancel</button>`,
          },

          // {
          //   id: "reject",
          //   template: ` <button class='btn btn-danger btn-xs' title="confirm" >Reject</button>`
          // },
          // {
          //   id: "archive",
          //   template: ` <button class='btn btn-danger btn-xs' title="archive" >archive</button>`
          // },

          "Search",
          "ColumnChooser",
        ];
  }
  @observable
  editMode = "Dialog";
  @action
  setEditMode = () => {
    if (this.editMode === "Inline") {
      this.editMode = "Dialog";
      return;
    } else {
      this.editMode = "Inline";
      return;
    }
  };
  @computed
  get editSettings() {
    return {
      allowEditing: true,
      allowAdding: true,
      mode: this.editMode,
    };
  }
  editparams = { params: { popupHeight: "300px" } };

  a = "";
  @observable
  date = new Date();
  @observable
  Clients = [];
  @observable
  tempClientOrders = [];
  @observable
  ClientsNames = ["Unknown"];
  @observable
  ClientContactPerson = [];
  @observable
  Agents = [];
  @observable
  ClientContactPersonName = [];
  @observable
  ContactPerson = [];
  @observable
  Orders = [];
  @observable
  status = [
    "Start",
    "Qu ote",
    "Client",
    "Arranged",
    "Confirmed",
    "Rejected",
    "Loaded",
  ];
  @observable
  packing = ["Pallet", "Piece", "Rolls", "Cartons", "Full"];
  @observable
  temprow = [];
  @observable
  index = null;
  @observable
  allTrucks = [];
  @observable
  shipCountryName = [];
  @observable
  destCountryName = [];
  @observable
  width = "";
  @observable
  AgentName = [];
  @observable
  tempAgentname = [];
  @observable
  tempAgent = [];
  @observable
  Questions = "";
  @observable
  Total = "";
  @observable
  AlertOpen = false;
  @observable
  alertsms = "Hello Lusartrans";
  @observable
  UserInfo = {};
  @observable
  User = "";
  @observable
  tableWidth = 430;
  @observable
  minDate = new Date();
  @observable
  maxDate = new Date();
  @observable
  count = 0;
  @observable
  clientOrders = [];
  @observable
  ClientOrdersSwitcher = false;
  @observable
  Badge = 0;
  @observable
  BaseUser = "";
  @observable
  Users = [""];
  @observable
  value = "k.soghoyan@d2d.am";
  @observable
  Loading = true;
  @observable
  allOrders = [];
  @observable
  CreateMail = {
    MailTo: "",
    ccTo: "",
    Subject: "",
    ContactPerson: "",
    User: "",
    UserMail: "",
  };
  @observable
  editingGoods = false;
  @observable
  filters = "";
  @observable
  setFilters = "";
  @observable
  editingRow = {};
  @observable
  filteredOrders = [];
  databaseRef = "";
  databaseClients = "";
  databaseAgents = "";
  @observable
  inited = false;
  @observable
  year = "2019";
  @observable
  listening = false;
  constructor() {
    this.setDefaullDate();
  }
  @observable
  ActiveTrucks = [];
  @observable
  ActiveTrucksNames = [];

  @observable
  completedCount = 0;
  @observable
  Percent = 0;

  @action
  setDefaullDate = () => {
    this.minDate.setHours(0, 0, 0, 0);
    //this.minDate.setMonth(this.minDate.getMonth() - 2);
  };
  init = async () => {
    this.access = !!!firebase.auth().currentUser.email.includes("operator");
    this.databaseRef = firebase.database().ref().child("Order");
    this.databaseClients = firebase.database().ref().child("Clients");
    this.databaseAgents = firebase.database().ref().child("Agents");
    this.startindex = this.index;
    this.User = firebase.auth().currentUser.email;

    this.GetBase(this.User);

    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (!firebaseUser) {
        firebase.auth().signOut();
      }
    });

    this.databaseClients.once("value", (snap) => {
      snap.forEach((data) => {
        this.updateClients(data.val(), data.key);
      });
    });

    this.databaseAgents.on("child_added", (snap) => {
      const response = snap.val();
      const key = snap.key;
      this.updateAgents(response, key);
    });
  };
  Indexing = {};
  GetBase = async (User, all) => {
    if (this.loaded) {
      return;
    }

    this.loaded = true;

    if (User)
      this.BaseUser = User.split("@")[0].split(".").join("") + this.year;

    firebase
      .database()
      .ref()
      .child("Settings")
      .child("ordersSettings")
      .child(this.BaseUser)
      .on("value", (snap) => {
        if (snap.numChildren() === 0) {
          this.setSettings(this.defaultSettings, true);
          this.Loading = false;
        } else {
          snap.forEach((a) => {
            this.tmep[a.key] = a.val();
          });
          this.Loading = false;
        }
      });

    this.allOrders = [];

    this.Indexing = {};

    firebase
      .database()
      .ref()
      .child("ForAdmin")
      .orderByChild("compareDate")
      .startAt(Math.round(new Date(this.minDate).getTime() / 1000))
      .on("value", (snap) => {
        let temp = [];

        snap.forEach((a) => {
          const index = this.Orders.findIndex((b) => b.id === a.val().id);

          if (index === -1) temp.push(a.val());
          else {
            this.Orders[index] = a.val();
            return;
          }
          if (temp.length === snap.numChildren()) {
            this.Orders = temp;
            this.completedCount = 0;
            this.Percent = 0;

            this.completedCount = this.Orders.filter(
              (a) => a.status === "completed"
            ).length;
            let tempPrice = this.Orders.filter(
              (a) => a.status === "completed"
            ).reduce((a, b) => a + (+b["price"] || 0), 0);
            this.Percent = (tempPrice * 15) / 100;
          } else {
            if (temp.length + this.Orders.length === snap.numChildren()) {
              this.Orders.push(a.val());
              this.completedCount = 0;
              this.Percent = 0;
              this.completedCount = this.Orders.filter(
                (a) => a.status === "completed"
              ).length;
            }
          }
        });
      });

    this.loaded = true;
  };
  // @action oldLoading = async () => {
  //   let b = [];
  //   this.allOrders = [];
  //   await firebase
  //     .database()
  //     .ref()
  //     .child("MobileOrders")
  //     .once("value", (snap) => {
  //       snap.forEach((a) => {
  //         const sss = a.val();
  //         Object.keys(sss).forEach((order) => {
  //           let comparetoDate = new Date(
  //             sss[order].compareDate * 1000
  //           ).toDateString();
  //           this.allOrders.push(sss[order]);

  //           if (sss[order].id)
  //             firebase
  //               .database()
  //               .ref()
  //               .child("ForAdmin")
  //               .child(sss[order].id)
  //               .set(sss[order]);
  //           if (comparetoDate === new Date(this.minDate).toDateString()) {
  //             b.push(sss[order]);
  //           }
  //         });
  //       });

  //       this.Orders = b;
  //     });
  // };

  updateClients = (response, key) => {
    const id = key;
    const ClientName = response.CompanyName;
    const ContactPerson = response.contactPerson;
    const Annex = response.Annex;

    const row = {
      id: id,
      CompanyName: ClientName,
      ContactPerson: ContactPerson,
      Annex: Annex,
    };
    this.Clients.push(row);
    this.ClientsNames.push(row.CompanyName);
  };

  updateAgents = (response, key) => {
    const id = key;
    const AgentName = response.CompanyName;
    const ContactPerson = response.contactPerson;
    const Mail = response.Mail;
    const Address = response.Address;

    const row = {
      id: id,
      AgentName: AgentName,
      ContactPerson: ContactPerson,
      Mail: Mail,
      Address: Address,
    };

    this.AgentName.push(AgentName);
    this.Agents.push(row);
  };
  temprow;

  handleChangeMinDate = (event, date) => {
    this.minDate = date;
    this.temprow = [];
    this.tempOrders = this.Orders;
    this.Orders = [];
    this.loaded = false;
    this.GetBase();
  };

  handleChangeMaxDate = (event, date) => {
    this.maxDate = date;
    this.temprow = [];
    this.tempOrders = this.Orders;

    this.Orders = this.Orders.filter(
      (a) =>
        new Date(a.compareDate * 1000) >= new Date(this.minDate) &&
        new Date(a.compareDate * 1000) <= new Date(this.maxDate)
    );
  };
  @action
  wrap = () => {
    this.isWraped = !this.isWraped;
    this.GetBase(this.User);
  };

  //add row
  @observable
  addedRow = {};

  @observable
  AlertOpen = false;

  @observable
  alertsm = "";

  @action
  OpenAlert = (alertsms) => {
    this.AlertOpen = true;
    this.alertsm = alertsms;
  };

  @action
  setSelectedIndex = (selected) => {
    const index = this.Orders.findIndex((a) => a.id === selected.data.id);
    if (index === -1) {
      return;
    }
    this.index = index;
    this.enabled = "";
  };

  @action
  setIndex = (id) => {
    this.index = this.Orders.findIndex((a) => a.id === id);
  };
  @observable
  text = null;

  @observable
  contactPersons = [];
  @action
  closeAlert = () => {
    this.AlertOpen = false;
  };
  @observable
  MapUrl = "";

  @observable
  colorObject = null;
  @observable
  comment = "";
  @action
  setColorOptions = (key, index) => {
    this.colorObject = {
      id: this.Orders[index].id,
      field: key,
    };
  };
  @action
  setColor = (color) => {
    const temp = this.Orders.slice();
    this.databaseRef
      .child(this.BaseUser)
      .child(this.colorObject.id)
      .child("color")
      .child(this.colorObject.field)
      .set(color);
    const index = temp.findIndex((a) => a.id === this.colorObject.id);

    temp[index].color[this.colorObject.field] = color;
    this.Orders = temp.slice();
  };
  @observable
  CreateMail = {};

  @observable
  OnQuest = false;

  @observable
  radiuses = [];
  @observable
  timers = {};
  @observable
  curradius = 3000;
  @action
  onActivateOrder = async (row) => {
    const selectedOrder = this.Orders[this.index];
    selectedOrder.activated = true;

    selectedOrder.status = "new";
    await firebase
      .database()
      .ref()
      .child("MobileOrders")
      .child(selectedOrder.client)
      .child(selectedOrder.id)
      .set(null);
    firebase
      .database()
      .ref()
      .child("MobileOrders")
      .child(selectedOrder.client)
      .child(selectedOrder.id)
      .set(selectedOrder);
  };
  @action
  onCancelOrder = () => {
    if (this.index === null || this.index === undefined) {
      return;
    }
    const selectedOrder = this.Orders[this.index];

    firebase
      .database()
      .ref()
      .child("MobileOrders")
      .child(selectedOrder.client)
      .child(selectedOrder.id)
      .child("status")
      .set("canceled");

    firebase
      .database()
      .ref()
      .child("ActiveOrders")
      .child(selectedOrder.id)
      .child("truck")
      .once("value", (sn) => {
        const id = sn.val();
        if (id) {
          firebase
            .database()
            .ref()
            .child("RegisteredTrucks")
            .child(id)
            .child("fcm")
            .once("value", (er) => {
              const fcm = er.val();
              fetch("https://fcm.googleapis.com/fcm/send", {
                method: "POST",
                headers: {
                  Authorization:
                    "key=AAAAlJYf2Tc:APA91bFc01tEi8LFLwYubqTv1NAlbFHW159LJnDeQt3jfaD2eyJEIm-mx8dniizpCVt1nIlWaHmcyLJ-ootL6z5CwyzC7dOMJcU8pqaDwDIJ4T5qKTmgHSU4X6WUaxkr8QjKTSbXeb6b",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  to: fcm,
                  data: {
                    Nick: "Mario",
                    body: "canceled",

                    android: { priority: "high" },
                    priority: 10,
                  },
                }),
              });
            });
        }
      });
    firebase
      .database()
      .ref()
      .child("ActiveOrders")
      .child(selectedOrder.id)
      .set(null);

    firebase
      .database()
      .ref()
      .child("ClientsData")
      .child(selectedOrder.client)
      .child("fcm")
      .once("value", (snap) => {
        const fcm = snap.val();
        const massage = `պատվեր N${selectedOrder.id},Չեղարկված է`;
        this.sendNotification(massage, fcm, selectedOrder.id);
      });
  };
  @action
  updateOrderTruchs = (activeOrder) => {
    this.curradius = this.curradius + 3000;
    let actualTrucks = this.ActiveTrucks;
    actualTrucks = actualTrucks.filter((a) =>
      geolib.isPointWithinRadius(
        a.coordinate,
        activeOrder.coords,
        this.curradius
      )
    );
    firebase
      .database()
      .ref()
      .child("ActiveOrders")
      .child(activeOrder.id)
      .child("trucks")
      .set(actualTrucks);
  };

  @observable
  showComment = false;
  commentIndex = null;
  @action
  onShowComment = () => {
    if (this.index === "") {
      this.OpenAlert("Please select row");
      return;
    }
    this.showComment = true;
    this.commentIndex = this.index;
    this.comment = this.Orders[this.commentIndex].priceComment || "";
  };
  @action
  closeComment = () => {
    this.showComment = false;
  };
  @action
  saveComment = (comment) => {
    this.Orders[this.commentIndex].priceComment = comment;
    firebase
      .database()
      .ref()
      .child("Order")
      .child(this.BaseUser)
      .child(this.Orders[this.commentIndex].id)
      .child("priceComment")
      .set(comment);
  };
  @observable
  dimensions = null;

  tmep = {
    width: {},
    visible: {},
  };
  @computed
  get settings() {
    return this.tmep;
  }
  @action
  setSettings = (settings, first = false) => {
    this.tmep = settings;
    firebase
      .database()
      .ref()
      .child("Settings")
      .child("ordersSettings")
      .child(this.BaseUser)
      .set(settings);
  };

  @observable
  defaultSettings = {};

  @action
  setDefaultSettings = (settings) => {
    this.defaultSettings = settings;
  };
  @observable
  mailSettings = {};

  @observable
  ShowSendOrder = false;
  @observable
  truckNumber = "";
  @observable
  activeTrucks = [];
  @action
  PrepareSendOrder = async () => {
    this.activeTrucks = [];
    let index = this.index;

    if (index === undefined || index === null) {
      return;
    }
    let type = this.Orders[index].goodsBreaf;
    if (type === "expresss" || type === "expressBike") type = "express";
    await firebase
      .database()
      .ref()
      .child("InternalTrucks")
      .orderByChild("type")
      .equalTo(type)
      .once("value", (snap) => {
        const response = snap.val();
        if (!response) {
          return;
        }
        Object.keys(response).forEach((q) => {
          this.activeTrucks.push(response[q]);
        });
      });
    this.activeTrucks.forEach(async (r) => {
      await firebase
        .database()
        .ref()
        .child("RegisteredTrucks")
        .child(r.id)
        .child("name")
        .once("value", (d) => {
          this.activeTrucks[this.activeTrucks.indexOf(r)].name = d.val();
        });
    });
    this.selectedNumber = this.activeTrucks[0].id;
    this.ShowSendOrder = true;
  };

  @action
  sendOrder = async (number) => {
    let index = this.index;
    if (index === undefined || index === null) return;
    let order = this.Orders[index];
    if (order.payment === "wallet") {
      order.payment = "balance";
    }
    if (order.to && order.to.includes("+374")) {
      order.to = "+374" + order.to;
    }
    order = removeUndefined(order);
    const activeOrder = {
      clientId: order.client,
      orderId: order.id,
      address: order.loadingAddress,
      coords: order.loadingCoords,
      type: order.goodsBreaf,
      truck: number,
      unloadingAddress: order.unloadingAddress ? order.unloadingAddress : "",
      unloadingCoords: order.unloadingCoords ? order.unloadingCoords : "",
      to: order.to ? order.to : "",
      payment: order.payment ? order.payment : "",
      urgent: true,
      comment: order.comment ? order.comment : "",
    };
    await firebase
      .database()
      .ref()
      .child("ActiveOrders")
      .child(activeOrder.orderId)
      .set(activeOrder);
    await firebase
      .database()
      .ref()
      .child("MobileOrders")
      .child(order.client)
      .child(order.id)
      .child("status")
      .set("wait");
    firebase
      .database()
      .ref()
      .child("RegisteredTrucks")
      .child(number)
      .child("fcm")
      .once("value", (sn) => {
        this.sendNotification("Պատվեր", sn.val());
      });
  };
  @action
  ShowAll = () => {
    this.Orders = [];
    this.Orders = this.allOrders;
  };
  @observable
  mapCoords;
  @action
  showMap = (loading) => {
    let index = this.index;
    let order = this.Orders[index];
    if (loading === "loading") {
      this.mapCoords = order.loadingCoords;
    } else {
      this.mapCoords = order.unloadingCoords;
    }
  };
  foolowingTruck = "";
  @action
  follow = () => {
    let index = this.index;
    let order = this.Orders[index];
    if (!order || !order.selectedTruck) {
      return;
    }
    this.foolowingTruck = order.selectedTruck.split("/")[0];
    firebase
      .database()
      .ref()
      .child("InternalTrucks")
      .child(order.selectedTruck.split("/")[0])
      .child("coordinate")
      .on("value", (snap) => {
        this.mapCoords = snap.val();
      });
    this.showCreateOrder = true;
  };
  @action unfollow = () => {
    if (!this.foolowingTruck) return;
    let index = this.index;
    let order = this.Orders[index];
    firebase
      .database()
      .ref()
      .child("InternalTrucks")
      .child(order.selectedTruck.split("/")[0])
      .child("coordinate")
      .off();
  };
  sendNotification = (massage, to) => {
    fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers: {
        Authorization:
          "key=AAAAlJYf2Tc:APA91bFc01tEi8LFLwYubqTv1NAlbFHW159LJnDeQt3jfaD2eyJEIm-mx8dniizpCVt1nIlWaHmcyLJ-ootL6z5CwyzC7dOMJcU8pqaDwDIJ4T5qKTmgHSU4X6WUaxkr8QjKTSbXeb6b",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: to,

        data: {
          Nick: "Mario",
          body: massage,

          android: { priority: "high" },
          priority: 10,
        },
      }),
    });
    fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers: {
        Authorization:
          "key=AAAAlJYf2Tc:APA91bFc01tEi8LFLwYubqTv1NAlbFHW159LJnDeQt3jfaD2eyJEIm-mx8dniizpCVt1nIlWaHmcyLJ-ootL6z5CwyzC7dOMJcU8pqaDwDIJ4T5qKTmgHSU4X6WUaxkr8QjKTSbXeb6b",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: to,

        notification: {
          Nick: "Mario",
          body: massage,

          android: { priority: "high" },
          priority: 10,
        },
      }),
    });
  };

  @action
  sendVoice = (file) => {
    firebase
      .storage()
      .ref()
      .child(this.selectedNumber)
      .put(file)
      .then(() => {
        firebase
          .database()
          .ref()
          .child("Voice")
          .child(this.selectedNumber)
          .set(new Date().toLocaleTimeString());
      });
  };

  setTarrif = (minimal) => {
    firebase
      .database()
      .ref()
      .child("Tarifs")
      .child(0)
      .child("minimal")
      .set(minimal);
    firebase
      .database()
      .ref()
      .child("RegisteredTrucks")
      .orderByChild("type")
      .equalTo("express")
      .once("value", (snap) => {
        snap.forEach((a) => {
          firebase
            .database()
            .ref()
            .child("RegisteredTrucks")
            .child(a.key)
            .child("minimal")
            .set(minimal);
        });
      });
    alert(`Minimal is now ${minimal}`);
  };
  @action
  onAfterSaveCell = (e) => {
    const data = removeUndefined(e);
    console.log(data);
    firebase
      .database()
      .ref()
      .child("MobileOrders")
      .child(data.client)
      .child(data.id)
      .update({ ...data });
  };
}

const ordersStore = new OrdersStore();
export default ordersStore;
