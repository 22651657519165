import React from "react";
import GoogleMapReact from "google-map-react";
import truckMarker from "../pics/truckarker.png";
import { getCoordsByAddress, getCoordsById } from "../geo/geolocation";
import firebase from "firebase/app";
import "firebase/database";

const API_KEY = "AIzaSyCFiFctFOz2T22CV-QKVKpnFHYUnWVRyqc";
const GOOGLE_GEOCODE_API = "https://maps.google.com/maps/api/geocode/json";
const GOOGLE_PLACE_API =
  "https://glacial-headland-92187.herokuapp.com/https://maps.googleapis.com/maps/api/place/autocomplete/json";
const LATITUDE_DELTA = 0.01995;
const LONGITUDE_DELTA = LATITUDE_DELTA * 0.5625;
export default class CreateOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      truckType: "express",
      address: "",

      center: { lat: 40.1772, lng: 44.50349 },
      suggestions: [],
      switch: true,
      delayed: true,
      unloadingList: [""],
    };
  }
  static defaultProps = {
    center: { lat: 40.1772, lng: 44.50349 },
    zoom: 11,
  };
  componentDidMount() {
    const { center } = this.state;
    if (this.props.center) {
      center.lng = this.props.center.longitude;
      center.lat = this.props.center.latitude;
      this.setState(center);
    }
  }
  openMapAddress = async (item) => {
    this.mapOff = true;
    let reg = {};
    let currentLocation = {};
    if (item.id) {
      currentLocation = await getCoordsById(item.id);
      reg = {
        latitude: currentLocation.lat || currentLocation.longitude,
        longitude: currentLocation.lng || currentLocation.longitude,
        longitudeDelta: LONGITUDE_DELTA,
        latitudeDelta: LATITUDE_DELTA,
      };
    }
    if (!item.coords && !item.id) {
      currentLocation = await getCoordsByAddress(item);
      reg = {
        latitude: currentLocation.lat || currentLocation.latitude,
        longitude: currentLocation.lng || currentLocation.longitude,
        longitudeDelta: LONGITUDE_DELTA,
        latitudeDelta: LATITUDE_DELTA,
      };
    } else if (item.coords) {
      debugger;
      reg = {
        ...item.coords,
        longitudeDelta: LONGITUDE_DELTA,
        latitudeDelta: LATITUDE_DELTA,
      };
    }
    // if (this.switch === "unloading") {
    //   this.unRegion = reg;
    //   this.setState({

    //     unloadingAddress: item.text ? item.text : item,
    //   });
    //   return;
    // }
    if (this.state.switch) {
      this.loadingCoords = reg;
      this.loadingAddress = item.text ? item.text : item;
    } else {
      this.unloadingCoords = reg;
      this.unloadingAddress = item.text ? item.text : item;
    }
    this.setState({
      center: { lat: reg.latitude || reg.lat, lng: reg.longitude || reg.lng },

      showModal: false,
      mapModal: true,

      suggestions: [],
    });
    if (this.unlaodingIndex !== undefined) {
      const temp = this.state.unloadingList;
      temp[this.unlaodingIndex] = {
        address: item.text ? item.text : item,
        coords: reg,
      };
      this.setState({ unloadingList: temp });
    } else {
      this.setState({ address: item.text ? item.text : item });
    }
    if (this.loadingCoords && this.unloadingCoords) {
      this.getDistance();
    }
  };
  getAddressByCoords = async (e) => {
    if (this.mapOff) {
      return;
    }
    if (!this.props.creating) return;
    const latLng = `${e.center.lat},${e.center.lng}`;
    const url = `${GOOGLE_GEOCODE_API}?latlng=${encodeURI(
      latLng
    )}&key=${API_KEY}`;
    const response = await this.handleUrl(url);
    if (response && response.results)
      this.setState({
        address: response.results[0].formatted_address,
        center: e.center,
      });
    if (this.state.switch) {
      this.loadingCoords = e.center;
      this.loadingAddress = response.results[0].formatted_address;
    } else {
      this.unloadingCoords = e.center;
      this.unloadingAddress = response.results[0].formatted_address;
      if (this.unlaodingIndex !== undefined) {
        const temp = this.state.unloadingList;
        temp[this.unlaodingIndex] = response.results[0].formatted_address;
        this.setState({ unloadingList: temp });
      } else {
        this.setState({ address: response.results[0].formatted_address });
      }
    }
  };
  handleUrl = async (url) => {
    const response = await fetch(url, { dataType: "jsonp" }).catch(() =>
      Promise.reject(new Error("Error fetching data"))
    );
    const json = await response.json().catch(() => {
      return Promise.reject(new Error("Error parsing server response"));
    });

    if (json.status === "OK") {
      return json;
    }
    return Promise.reject(
      new Error(`Server returned status code ${json.status}`)
    );
  };
  createOrder = async () => {
    if (
      this.state.phone.length < 8 ||
      !this.state.address ||
      !this.state.center
    ) {
      alert("տվյալների անճշտություն");
      return;
    }
    let idx = Math.floor(10000000 + Math.random() * 8000000).toString();
    const row = {
      id: idx,
      status: "new",
      client: `+374${this.state.phone}`,
      loadingAddress: this.loadingAddress,
      //unloadingAddress: this.unloadingAddress,
      destination: "Armenia",
      loadingdate: new Date().toLocaleString("en-GB"),
      date: new Date().toLocaleString("en-GB"),
      goodsBreaf: this.state.truckType,
      compareDate: Math.round(new Date().getTime() / 1000),
      destinationType: "Armenia",
      loadingCoords: this.loadingCoords,
      unloadingCoords: this.unloadingCoords,
      version: 6.0,
      to: `+374${this.state.to}` || "",
      comment: this.state.comment || "",
      delayed: this.state.delayed,
      // comment:this.state.banvor>0?this.state.banvor+"հոգի":""
    };
    // firebase
    //   .database()
    //   .ref()
    //   .child("MobileOrders")
    //   .child(`+374${this.state.phone}`)
    //   .child(idx)
    //   .set(row);
    if (this.state.unloadingList.length > 0) {
      debugger;
      for (let index = 0; index < this.state.unloadingList.length; index++) {
        debugger;
        if (
          !this.state.unloadingList[index].address ||
          !this.state.unloadingList[index].coords
        ) {
          continue;
        }
        row.unloadingAddress = this.state.unloadingList[index].address;
        row.unloadingCoords = this.state.unloadingList[index].coords;

        idx = Math.floor(10000000 + Math.random() * 8000000).toString();
        row.id = idx;

        await firebase
          .database()
          .ref()
          .child("MobileOrders")
          .child(`+374${this.state.phone}`)
          .child(idx)
          .set(row);
      }
    }

    this.close();
  };
  close = () => {
    this.props.close();
  };
  getSuggestions = (index) => async (e) => {
    if (index === undefined) {
      this.unlaodingIndex = undefined;
      this.setState({ address: e.target.value });
    } else {
      const temp = this.state.unloadingList;
      temp[index] = { address: e.target.vlaue };
      this.setState({ unloadingList: temp });
    }
    let addresses = await this.getAddressSuggestions(e.target.value, false);
    //addresses= await getAddressSuggestions(text,this.props.international);
    if (addresses && !addresses.predictions) {
      let temp = [];

      addresses.forEach((a) => {
        if (a[1].includes("Armenia") && a[1].includes("Yerevan"))
          temp.push(a[1]);
      });
      this.setState({ suggestions: temp });
    } else if (addresses && addresses.predictions) {
      let temp = [];

      temp = addresses.predictions.slice(0, 8).map((a) => {
        return { text: a.description, id: a.place_id };
      });
      if (index !== undefined) {
        this.unlaodingIndex = index;
      }
      this.setState({ suggestions: temp });
    }
  };
  getAddressSuggestions = async (address, international) => {
    try {
      return await this.autocomplete(address, international);
    } catch (err) {
      return [];
    }
  };

  YhandleUrl = async (address) => {
    const response = await fetch(
      `https://glacial-headland-92187.herokuapp.com/https://suggest-maps.yandex.ru/suggest-geo?format=json&callback=json&v=5&search_type=tp&part=${address}`
    ).catch(() => Promise.reject(new Error("Error fetching data")));
    if (response.status !== 200) {
      return false;
    }
    const myBlob = await response.blob();

    return new Promise((resolve, reject) => {
      const fileReaderInstance = new FileReader();
      //fileReaderInstance.readAsDataURL(myBlob);
      fileReaderInstance.onload = () => {
        let base64data = fileReaderInstance.result;
        let aaa = base64data.split(/,(.+)/)[1];
        aaa = aaa.slice(0, -2);
        try {
          resolve(JSON.parse(aaa));
        } catch {}
      };
      fileReaderInstance.readAsText(myBlob);
    });
  };
  autocomplete = async (address, international) => {
    if (!address) {
      return Promise.reject(new Error("Provided address is invalid"));
    }
    const surl = `${GOOGLE_PLACE_API}?&input=${encodeURI(
      address
    )}&key=${API_KEY}&types=establishment&language=en${
      !international ? "&components=country:am" : ""
    }`;
    const result = await this.YhandleUrl(address);
    if (result && result[0] !== undefined && result[0][1].includes("Armenia")) {
      return result;
    } else {
      return this.handleUrl(surl);
    }
  };
  getDistance = async () => {
    try {
      const distance = await this.getDistanceOneToOne(
        this.loadingCoords.latitude,
        this.loadingCoords.longitude,
        this.state.unloadingList[this.unlaodingIndex].coords.latitude,
        this.state.unloadingList[this.unlaodingIndex].coords.longitude
      );
      debugger;
      this.distance = distance;
      this.meter = Math.ceil(distance.value / 1000);
      this.calculateCost();
    } catch {}
  };
  getDistanceOneToOne = async (lat1, lng1, lat2, lng2) => {
    const Location1Str = lat1 + "," + lng1;
    const Location2Str = lat2 + "," + lng2;
    let ApiURL =
      "https://glacial-headland-92187.herokuapp.com/https://maps.googleapis.com/maps/api/distancematrix/json?";

    let params = `origins=${Location1Str}&destinations=${Location2Str}&key=${API_KEY}`; // you need to get a key
    let finalApiURL = `${ApiURL}${encodeURI(params)}`;
    let fetchResult = await fetch(finalApiURL); // call API
    let Result = await fetchResult.json(); // extract json

    return Result.rows[0].elements[0].distance;
  };
  calculateCost = () => {
    if (!this.meter) {
      return;
    }
    let cost = 0;

    if (this.meter < 6) {
      cost = 1000;
    } else {
      cost = (this.meter - 6) * parseFloat(100) + parseFloat(1000);
    }
    if (this.state.withReturn) cost = cost * 2;
    this.setState({
      distance: `${this.distance.text} ${Math.ceil(cost / 100) * 100}դ `,
      prePrice: `${Math.ceil(cost / 100) * 100}`,
    });
    return Math.ceil(cost / 100) * 100;
  };

  render() {
    const pin = this.props.center;
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: 20,
            backgroundColor: "#ADD8E6",
            borderRadius: 12,
          }}
        >
          <div style={{ width: "30%" }}>
            <div>Phone</div>
            <input
              value={this.state.phone}
              onChange={(e) => this.setState({ phone: e.target.value })}
              placeholder="+374"
            />
            <div>ToPhone</div>
            <input
              value={this.state.to}
              onChange={(e) => this.setState({ to: e.target.value })}
              placeholder="+374"
            />
            <select
              id="cars"
              value={this.state.truckType}
              onChange={(e) => this.setState({ truckType: e.target.value })}
            >
              <option value="express">express</option>

              <option value="halftone">halftone</option>
              <option value="ford">ford</option>
              <option value="gazele">gazele</option>
              <option value="tonnik">tonnik</option>
              <option value="tentones">tentones</option>
              <option value="pickup">pickup</option>
            </select>
            {/* <div>
              <label style={{ color: "white", marginRight: 10 }}>
                Loading
                <input
                  type="radio"
                  name="Loading"
                  checked={this.state.switch}
                  onChange={() => this.setState({ switch: true })}
                />
              </label>
              <label style={{ color: "white" }}>
                Unloading
                <input
                  type="radio"
                  name="Unloading"
                  checked={!this.state.switch}
                  onChange={() => this.setState({ switch: false })}
                />
              </label>
            </div> */}
            <div>Loading Address</div>
            <input
              value={this.state.address}
              ref="input"
              type="text"
              onChange={this.getSuggestions()}
              onClick={() => this.setState({ address: "" })}
            />
            <span>Unloading Adress</span>
            {this.state.unloadingList.map((item, index) => {
              return (
                <input
                  value={this.state.unloadingList[index].address}
                  ref="input"
                  type="text"
                  onChange={this.getSuggestions(index)}
                  onClick={() => this.setState({ switch: false })}
                />
              );
            })}

            {this.state.suggestions.length > 0 && (
              <div
                style={{
                  maxHeight: 160,
                  overflow: "scroll",
                  cursor: "pointer",
                }}
              >
                {this.state.suggestions.map((elem) => {
                  return (
                    <div
                      onClick={() => this.openMapAddress(elem)}
                      style={{
                        backgroundColor: "white",
                        borderBottomColor: "black",
                        borderBottomWidth: 1,
                        borderBottomStyle: "solid",
                        padding: 5,
                      }}
                    >
                      {elem.text || elem}
                    </div>
                  );
                })}
              </div>
            )}
            <div>Comment</div>
            <textarea
              style={{ width: 160, height: 120 }}
              value={this.state.comment}
              onChange={(e) => this.setState({ comment: e.target.value })}
            />
            <span style={{ color: "white", marginRight: 10 }}>Delayed</span>
            <input
              type="checkbox"
              checked={this.state.delayed}
              onChange={() => this.setState({ delayed: !this.state.delayed })}
            />
            <div style={{ color: "white" }}>{`${
              this.state.distance || 0
            } km `}</div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <input
                type="button"
                value="Add Unloading Address"
                style={{ padding: 10, marginLeft: 20 }}
                onClick={() => {
                  const temp = this.state.unloadingList;
                  temp.push(" ");
                  this.setState({ unloadingList: temp });
                }}
              />
              <input
                type="button"
                value="send"
                style={{ padding: 10, marginLeft: 20 }}
                onClick={this.createOrder}
              />
              <input
                type="button"
                value="cancel"
                style={{ padding: 10, marginLeft: 20 }}
                onClick={this.close}
              />
            </div>
          </div>

          <div
            style={{ width: "60%", height: "40vh", marginLeft: 30 }}
            onMouseDown={() => (this.mapOff = false)}
          >
            {/* {this.state.suggestions.length > 0 &&
              this.state.suggestions.map((a) => {
                a.text ? <div>{a.text}</div> : <div>a</div>;
              })} */}
            <GoogleMapReact
              // onChildMouseEnter={(a,childProps)=>this.setState({selectedId:{id:childProps.id ,type:childProps.type }  })}
              // onChildMouseLeave={()=>{this.setState({selectedId:null})}}
              onChange={this.getAddressByCoords}
              bootstrapURLKeys={{
                key: "AIzaSyA0zFm3S7uA-zjKeREvBBd85AhTVRCM57c",
              }}
              defaultCenter={this.state.center}
              defaultZoom={18}
              center={this.state.center}
            >
              <div
                lat={this.state.center.lat}
                lng={this.state.center.lng}
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: "red",
                  borderRadius: 5,
                }}
              >
                {" "}
              </div>
              {this.props.center && (
                <div
                  id={pin.id}
                  type={pin.type}
                  lat={pin.latitude}
                  lng={pin.longitude}
                >
                  <img
                    src={truckMarker}
                    style={{ transform: `rotate(${pin.heading}deg)` }}
                    alt=""
                  />
                </div>
              )}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  }
}
