import React, { Component } from "react";
import Contact from "./components/contact";
import Features from "./components/features";
import Header from "./components/header";
import Navigation from "./components/navigation";
import JsonData from "./data/data.json";

export class App extends Component {
  state = {
    landingPageData: {},
  };
  getlandingPageData() {
    this.setState({ landingPageData: JsonData });
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <div>
        <Navigation />
        <Header data={this.state.landingPageData.Header} />
        <Features data={this.state.landingPageData.Features} />

        <Contact data={this.state.landingPageData.Contact} />
      </div>
    );
  }
}

export default App;
