import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/database";
export default function StoreCategories() {
  const [data, setData] = useState([]);
  const [category, setCategory] = useState("");
  const [rus, setRus] = useState("");
  const [arm, setArm] = useState("");
  useEffect(() => {
    firebase
      .database()
      .ref()
      .child("StoreCategories")
      .on("child_added", (snap) => {
        data.push(snap.val());
        setData([...data]);
      });
    return () => {
      setData([]);
    };
  }, []);
  const addCategorie = () => {
    data.push({ category, rus, arm });
    firebase.database().ref().child("StoreCategories").set(data);
    setData([...data]);
  };
  const removeCategpry = (index) => () => {
    data.splice(index, 1);
    setData([...data]);
    firebase.database().ref().child("StoreCategories").set(data);
  };
  return (
    <div style={{ marginTop: 50 }}>
      <section>
        eng main
        <input value={category} onChange={(e) => setCategory(e.target.value)} />
        rus
        <input value={rus} onChange={(e) => setRus(e.target.value)} />
        arm
        <input value={arm} onChange={(e) => setArm(e.target.value)} />
        <input type="button" value="add" onClick={addCategorie} />
      </section>
      {data.map((category, index) => {
        return (
          <div key={index} style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginLeft: 10 }}>{category.category}</div>
            <div style={{ marginLeft: 10 }}>{category.rus}</div>
            <div style={{ marginLeft: 10 }}>{category.arm}</div>
            <input
              style={{ marginLeft: 15 }}
              type="button"
              value="X"
              onClick={removeCategpry(index)}
            />
          </div>
        );
      })}
    </div>
  );
}
