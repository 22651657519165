import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import AppBar from "material-ui/AppBar";
import Paper from "material-ui/Paper";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

class AgentsDisplay extends Component {
  constructor(props) {
    super(props);
    this.databaseRef = firebase.database().ref().child("Agents");
    this.updateLocalState = this.updateLocalState.bind(this);
    this.change = this.change.bind(this);
    this.addPost = this.addPost.bind(this);
    this.onAddRow = this.onAddRow.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.expandComponent = this.expandComponent.bind(this);
    this.upDatePost = this.upDatePost.bind(this);
    this.expandComponent = this.expandComponent.bind(this);
    this.onAfterSaveCell = this.onAfterSaveCell.bind(this);

    this.state = {
      Access: false,
      Agents: [],
      tempRows: [],
      index: "",
      jobTypes: ["Pickup", "WareHouse", "Forwarder", "Air", "Sea"],
      ShowTypes: false,
      blankContact: [],
      tableWidth: 430,
    };
  }

  componentDidMount() {
    let User = firebase.auth().currentUser.email;
    this.setState({ tableWidth: window.innerHeight * 0.7 });
    firebase
      .database()
      .ref()
      .child("Users")
      .orderByChild("Email")
      .equalTo(User)
      .on("child_added", (snap) => {
        const response = snap.val();
        if (response.Access !== undefined) {
          this.setState({ Access: true });
        } else {
          this.setState({ Access: true });
        }
      });

    const { updateLocalState } = this;
    this.databaseRef.on("child_added", (snap) => {
      const response = snap.val();
      const key = snap.key;
      updateLocalState(response, key);
    });
  }

  updateLocalState(response, key) {
    const tempRows = this.state.tempRows;
    const CompanyName = response.CompanyName;
    const Type = response.Type;
    const Tracks = response.Tracks;
    const id = key;
    const Address = response.Address;
    const Phone = response.Phone;
    const Mail = response.Mail;
    const Skype = response.Skype;
    let contactPersons = response.contactPerson;
    if (contactPersons === undefined) {
      contactPersons = [];
    }

    const row = {
      id: id,
      CompanyName: CompanyName,
      Type: Type,
      Tracks: Tracks,
      Phone: Phone,
      Mail: Mail,
      Skype: Skype,
      Address: Address,
      subcomponent: contactPersons,
      //   price:price,
    };
    tempRows.push(row);
    this.setState({ Agents: tempRows });
  }
  addPost(Company) {
    const postToSave = { Company };

    this.databaseRef.push().set(postToSave.Company);
  }
  change(e) {
    if (e.target.value === "Forwarder") {
      this.setState({ ShowTypes: true });
    } else {
      this.setState({ ShowTypes: false });
    }
  }
  customNameField = (column, attr, editorClass, ignoreEditable) => {
    return (
      <select onChange={this.change} className={`${editorClass}`} {...attr}>
        {this.state.jobTypes.map((name) => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </select>
    );
  };

  onAddRow(row) {
    //this.state.testrow.push(row);
    this.addPost(row);
  }
  remote(remoteObj) {
    // Only cell editing, insert and delete row will be handled by remote store
    remoteObj.cellEdit = true;
    remoteObj.insertRow = true;
    remoteObj.dropRow = true;
    return remoteObj;
  }

  onRowSelect(row, isSelected, e) {
    this.setState({ index: row.id });
  }

  onCellEdit = (row, fieldName, value) => {
    if (!this.state.Access) {
      alert("Dont Have Permission");
      return;
    }
    const { Agents } = this.state;
    let rowIdx;
    const targetRow = Agents.find((prod, i) => {
      if (prod.id === row.id) {
        rowIdx = i;
        return true;
      } else {
        return false;
      }
    });
    if (targetRow) {
      targetRow[fieldName] = value;
      Agents[rowIdx] = targetRow;
      this.setState({ Agents });
      this.forceUpdate();
    }

    this.databaseRef.child(row.id).child(fieldName).set(value);
  };

  isExpandableRow(row) {
    return true;
  }

  upDatePost(row) {
    let index = this.state.Agents.findIndex(
      (todo) => todo.id === this.state.index
    );
    row.id = this.state.Agents[index].subcomponent.length;
    const postToSave = { row };

    this.state.Agents[index].subcomponent.push(postToSave.row);
    this.databaseRef
      .child(this.state.index)
      .child("contactPerson")
      .set(this.state.Agents[index].subcomponent);
    this.forceUpdate();
  }
  expandComponent(row) {
    const options = {
      exportCSVText: "my_export",
      insertText: "Ad ContactPerson",
      deleteText: "my_delete",
      saveText: "my_save",
      closeText: "my_close",
      onAddRow: this.upDatePost,
    };
    const cellEditProp = {
      mode: "dbclick",
      blurToSave: true,
      afterSaveCell: this.onAfterSaveCell,
    };

    return (
      <div style={{ marginTop: 0 }}>
        <h3>Contact Person</h3>

        <BootstrapTable
          data={row.subcomponent}
          cellEdit={cellEditProp}
          insertRow={this.state.Access}
          options={options}
        >
          <TableHeaderColumn isKey dataField="id" hidden hiddenOnInsert>
            Product ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="name"
            editable={{ type: "textarea", validator: this.jobNameValidator }}
            editColumnClassName="editing-jobsname-class"
            invalidEditColumnClassName="invalid-jobsname-class"
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn dataField="phone">Phone</TableHeaderColumn>
          <TableHeaderColumn dataField="mail">Mail</TableHeaderColumn>
          <TableHeaderColumn dataField="skype">Skype</TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }

  onAfterInsertSubcomponent(row) {
    let newRowStr = "";

    for (const prop in row) {
      newRowStr += prop + ": " + row[prop] + " \n";
    }
    alert("The new row is:\n " + newRowStr);

    this.state.testrow[0].subcomponent.push(newRowStr);
  }

  onAfterSaveCell(row, fieldName, value) {
    if (!this.state.Access) {
      alert("Dont have permission");
      return;
    }
    let index = this.state.Agents.findIndex(
      (todo) => todo.id === this.state.index
    );
    const data = this.state.Agents;

    data[index].subcomponent[row.id] = row;
    this.setState({ agents: data });
    this.databaseRef
      .child(this.state.index)
      .child("contactPerson")
      .child(row.id)
      .child(fieldName)
      .set(value);
  }
  createCustomModalHeader = (closeModal, save) => {
    const headerStyle = {
      fontWeight: "bold",
      fontSize: "medium",
      textAlign: "center",
      backgroundColor: "#00BCD4",
      color: "white",
    };
    return (
      <div className="modal-header" style={headerStyle}>
        <h3>New Agent</h3>
      </div>
    );

    // If you want have more power to custom the child of InsertModalHeader,
    // you can do it like following
    // return (
    //   <InsertModalHeader
    //     beforeClose={ this.beforeClose }
    //     onModalClose={ () => this.handleModalClose(closeModal) }>
    //     { ... }
    //   </InsertModalHeader>
    // );
  };
  render() {
    const options = {
      onAddRow: this.onAddRow,
      insertModalHeader: this.createCustomModalHeader,
      onCellEdit: this.onCellEdit,
    };

    const selectRowProp = {
      mode: "radio",
      clickToSelectAndEditCell: true,
      onSelect: this.onRowSelect,
      clickToExpand: this.onRowSelect,
    };

    const cellEditProp = {
      mode: "dbclick",
      blurToSave: true,
    };

    return (
      <MuiThemeProvider>
        <Paper style={{ marginTop: 40 }}>
          <AppBar
            title="Agents"
            showMenuIconButton={false}
            titleStyle={{ fontSize: 16, lineHeight: 3 }}
            style={{ zIndex: 0, height: 45, backgroundColor: "#414453" }}
          />
          <br />
          <br />
          <BootstrapTable
            striped
            hover
            version="4"
            options={options}
            insertRow={this.state.Access}
            data={this.state.Agents}
            remote={this.remote}
            cellEdit={cellEditProp}
            selectRow={selectRowProp}
            expandableRow={this.isExpandableRow}
            expandComponent={this.expandComponent}
            height={this.state.tableWidth}
            search
          >
            <TableHeaderColumn isKey dataField="id" hidden hiddenOnInsert>
              Product ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="CompanyName"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
            >
              Company Name
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="Type"
              editable={{
                type: "select",
                options: { values: this.state.jobTypes },
              }}
              customInsertEditor={{ getElement: this.customNameField }}
            >
              Company Type
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="Tracks"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
            >
              Trucks Type
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="Phone"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
            >
              Phone
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="Mail"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
            >
              Mail
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="Address"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
            >
              Address
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="Skype"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
            >
              Skype
            </TableHeaderColumn>
          </BootstrapTable>
        </Paper>
      </MuiThemeProvider>
    );
  }
}
export default AgentsDisplay;
