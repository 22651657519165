import { observable } from "mobx";

class MapStore {
  @observable
  pins = [];

  init = (pins) => {
    this.pins.push(pins);
  };
}

const mapStore = new MapStore();
export default mapStore;
