import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import AppBar from "material-ui/AppBar";
import Paper from "material-ui/Paper";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

class ClientsData extends Component {
  constructor(props) {
    super(props);
    this.databaseRef = firebase.database().ref().child("ClientsData");
    this.updateLocalState = this.updateLocalState.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    // this.upDatePost = this.upDatePost.bind(this);

    this.state = {
      Access: false,
      Agents: [],
      tempRows: [],
      index: "",
      jobTypes: [
        "halftone",
        "ford",
        "gazele",
        "tonnik",
        "tentones",
        "pickup",
        "express",
      ],
      ShowTypes: false,
      blankContact: [],
      tableWidth: 430,
    };
  }

  componentDidMount() {
    let User = firebase.auth().currentUser.email;
    this.setState({ tableWidth: window.innerHeight * 0.7 });
    firebase
      .database()
      .ref()
      .child("Users")
      .orderByChild("Email")
      .equalTo(User)
      .on("child_added", (snap) => {
        const response = snap.val();
        if (response.Access !== undefined) {
          this.setState({ Access: true });
        } else {
          this.setState({ Access: true });
        }
      });

    const { updateLocalState } = this;
    this.databaseRef.on("child_added", (snap) => {
      const response = snap.val();
      const key = snap.key;
      if (response.balance) updateLocalState(response, key);
    });
  }

  updateLocalState(response, key) {
    const { tempRows } = this.state;

    const name = response.name;

    const row = {
      id: key,
      name: name,
      balance: response.balance,
      fcm: response.fcm,
      fav: response.fav,
    };
    tempRows.push(row);
    this.setState({ Agents: tempRows });
  }

  onRowSelect(row, isSelected, e) {
    this.setState({ index: row.id });
  }

  onCellEdit = (row, fieldName, value) => {
    if (!this.state.Access) {
      alert("Dont Have Permission");
      return;
    }
    const { Agents } = this.state;
    let rowIdx;
    const targetRow = Agents.find((prod, i) => {
      if (prod.id === row.id) {
        rowIdx = i;
        return true;
      } else {
        return false;
      }
    });
    if (targetRow) {
      targetRow[fieldName] = +value;
      Agents[rowIdx] = targetRow;
      this.setState({ Agents });
    }
    if (fieldName === "balance") {
      value = Number(value);
      firebase
        .database()
        .ref()
        .child("Admin")
        .child("PaymentsLog")
        .child(row.id)
        .push({ amount: value, date: new Date() });
    }
    this.databaseRef.child(row.id).child(fieldName).set(value);
  };

  render() {
    const options = {
      onCellEdit: this.onCellEdit,
    };

    const selectRowProp = {
      mode: "radio",
      clickToSelectAndEditCell: true,
      onSelect: this.onRowSelect,
    };

    const cellEditProp = {
      mode: "dbclick",
      blurToSave: true,
    };
    console.log(this.state.Agents);
    return (
      <MuiThemeProvider>
        <Paper style={{ marginTop: 40 }}>
          <AppBar
            title="Reg Trucks"
            showMenuIconButton={false}
            titleStyle={{ fontSize: 16, lineHeight: 3 }}
            style={{ zIndex: 0, height: 45, backgroundColor: "#414453" }}
          />
          <br />
          <br />
          <BootstrapTable
            striped
            hover
            version="4"
            options={options}
            insertRow={this.state.Access}
            data={this.state.Agents}
            remote={true}
            cellEdit={cellEditProp}
            selectRow={selectRowProp}
            height={this.state.tableWidth}
            search
          >
            <TableHeaderColumn isKey dataField="id" width={120} hiddenOnInsert>
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="name"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              Name
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="balance"
              editable={{ type: "number", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              Balance
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="fcm"
              editable={{ type: "textarea", validator: this.jobNameValidator }}
              editColumnClassName="editing-jobsname-class"
              invalidEditColumnClassName="invalid-jobsname-class"
              width={120}
            >
              fcm
            </TableHeaderColumn>
          </BootstrapTable>
        </Paper>
      </MuiThemeProvider>
    );
  }
}
export default ClientsData;
