import React from "react";
import Paper from "material-ui/Paper/Paper";

export default class CommentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
    };
  }

  componentDidMount() {
    this.setState({ comment: this.props.defaultValue });
  }

  saveComment = (comment) => {
    this.props.ordersStore.saveComment(comment);
  };

  render() {
    const tempComment = this.state.comment;
    return (
      <Paper zDepth={2} style={{ backgroundColor: "yellow" }}>
        <textarea
          key="text"
          style={{ backgroundColor: "yellow", padding: 10 }}
          defaultValue={this.state.comment}
          value={this.state.comment}
          onChange={(e) => this.setState({ comment: e.target.value })}
        />
        <div>
          <button
            class="btn btn-success btn-xs"
            onClick={() => this.saveComment(tempComment)}
          >
            V
          </button>
          <button
            class="btn btn-danger btn-xs"
            style={{ marginLeft: 5 }}
            onClick={this.props.closeComment}
          >
            X
          </button>
        </div>
      </Paper>
    );
  }
}
