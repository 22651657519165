const API_KEY = "AIzaSyCFiFctFOz2T22CV-QKVKpnFHYUnWVRyqc";
const GOOGLE_GEOCODE_API = "https://maps.google.com/maps/api/geocode/json";
const GOOGLE_PLACE_API =
  "https://maps.googleapis.com/maps/api/place/autocomplete/json";
//Geolocation.setRNConfiguration(config);
const handleUrl = async (url) => {
  const response = await fetch(url).catch(() =>
    Promise.reject(new Error("Error fetching data"))
  );
  const json = await response.json().catch(() => {
    return Promise.reject(new Error("Error parsing server response"));
  });
  // console.log(json);
  return json;
};

const YhandleUrl = async (url) => {
  //debugger;
  const response = await fetch(url, { dataType: "jsonp" }).catch(() =>
    Promise.reject(new Error("Error fetching data"))
  );
  //debugger;
  const myBlob = await response.blob();
  return new Promise((resolve, reject) => {
    const fileReaderInstance = new FileReader();
    fileReaderInstance.readAsDataURL(myBlob);
    fileReaderInstance.onload = () => {
      let base64data = fileReaderInstance.result;
      let aaa = base64data.split(/,(.+)/)[1];
      aaa = aaa.slice(0, -2);
      try {
        resolve(JSON.parse(aaa));
      } catch {}
    };
    fileReaderInstance.readAsText(myBlob);
  });
};
const fromLatLngToAddress = async (lat, lng, service) => {
  if (!lat || !lng) {
    return Promise.reject(new Error("Provided coordinates are invalid"));
  }
  let latLng = null;
  if (service === "yandex") {
    latLng = `${lng} ${lat}`;
    const url = `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=3331be8e-2bb8-419e-a181-372850c27f53&lang=en_US&geocode=${latLng}`;
    //const url = `${GOOGLE_GEOCODE_API}?latlng=${encodeURI(latLng)}&key=${API_KEY}`;

    return handleUrl(url);
  } else {
    latLng = `${lat},${lng}`;
    const url = `${GOOGLE_GEOCODE_API}?latlng=${encodeURI(
      latLng
    )}&key=${API_KEY}`;
    return handleUrl(url);
  }
};

const fromAddressToLatLng = async (address, service) => {
  if (!address) {
    return Promise.reject(new Error("Provided address is invalid"));
  }
  // //debugger;
  //  const url = `${GOOGLE_GEOCODE_API}?address=${encodeURI(address)}&key=${API_KEY}`;
  let url = "";
  if (service === "yandex") {
    url = `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=3331be8e-2bb8-419e-a181-372850c27f53&lang=en_US&geocode=${address}`;
  } else {
    url = `${GOOGLE_GEOCODE_API}?place_id=${encodeURI(address)}&key=${API_KEY}`;
  }
  //console.log(url);
  return handleUrl(url);
};

const autocomplete = async (address, international) => {
  // console.log(address);
  if (!address) {
    return Promise.reject(new Error("Provided address is invalid"));
  }
  const surl = `${GOOGLE_PLACE_API}?&input=${encodeURI(
    address
  )}&key=${API_KEY}&types=establishment&language=en${
    !international ? "&components=country:am" : ""
  }`;
  const url = `https://suggest-maps.yandex.ru/suggest-geo?format=json&callback=json&v=5&search_type=tp&part=${address}`;
  // console.log(url)
  const result = await YhandleUrl(url);
  if (
    (result && result[0] !== undefined && result[0][1].includes("Armenia")) ||
    international
  )
    return result;
  else {
    return handleUrl(surl);
  }
};

export const getAddressByCoords = async (latitude, longitude) => {
  try {
    let response = null;
    response = await fromLatLngToAddress(latitude, longitude, "yandex");
    if (
      response &&
      response.response &&
      response.response.GeoObjectCollection
    ) {
      const address =
        response.response.GeoObjectCollection.featureMember[0].GeoObject.name +
        "," +
        response.response.GeoObjectCollection.featureMember[0].GeoObject
          .description;
      return address;
    } else {
      response = await fromLatLngToAddress(latitude, longitude, "google");
      const gadress = response.results[0].formatted_address;
      return gadress;
    }
  } catch (err) {
    // logException(err);
    return null;
  }
};
export const getCoordsById = async (id) => {
  const response = await fromAddressToLatLng(id, "google");
  // console.log(response.results[0].geometry.location);
  return response.results[0].geometry.location;
};
export const getCoordsByAddress = async (address, international) => {
  try {
    const response = await fromAddressToLatLng(address, "yandex");
    //debugger;
    //  console.log(response.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos);
    if (
      response &&
      response.response &&
      response.response.GeoObjectCollection
    ) {
      return {
        longitude: parseFloat(
          response.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(
            " "
          )[0]
        ),
        latitude: parseFloat(
          response.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(
            " "
          )[1]
        ),
      };
    } else {
      const gresponse = await fromAddressToLatLng(address, "google");
      if (gresponse.status === "OK") {
        return {
          longitude: gresponse.results[0].geometry.location.lng,
          latitude: gresponse.results[0].geometry.location.lat,
        };
      }
    }
    return null;
  } catch (err) {
    // logException(err);
    return null;
  }
};

export const getAddressSuggestions = async (address, international) => {
  try {
    return await autocomplete(address, international);
  } catch (err) {
    console.log(err);
    return [];
  }
};
