import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import { Provider } from "mobx-react";
import React, { Component, Suspense } from "react";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import ActiveOrders from "./ThreadDisplay/components/active-orders";
import AgentsDisplay from "./ThreadDisplay/components/AgentsDisplay";
import ClientsData from "./ThreadDisplay/components/clients-data";
import HeaderSide from "./ThreadDisplay/components/HeaderSide";
import Login from "./ThreadDisplay/components/Login";
import NewOrders from "./ThreadDisplay/components/new-orders";
import Policy from "./ThreadDisplay/components/policy";

import RegisteredTrucks from "./ThreadDisplay/components/RegisterdTrucks";

import goToMobileApp from "./ThreadDisplay/go-to-mobile-app";
import * as stores from "./ThreadDisplay/stores/";
import Page from "./page/App";
import StoreCategories from "./ThreadDisplay/storecategories";
import TestComp from "./ThreadDisplay/testComp";
const Map = React.lazy(() => import("./ThreadDisplay/components/Map"));

class App extends Component {
  constructor(props) {
    super(props);
    const configTest = {
      apiKey: "AIzaSyCNXJsXKD18ZaZpTkCIPKpOyLyH0GIIku4",
      authDomain: "lusartrans-3fa20.firebaseapp.com",
      databaseURL: "https://lusartrans-3fa20.firebaseio.com",
      projectId: "lusartrans-3fa20",
      storageBucket: "lusartrans-3fa20.appspot.com",
      messagingSenderId: "638173829431",
    };

    const config = {
      apiKey: "AIzaSyDTtOOyIilzJOBfIA5KmRaIfLiY9HiAy94",
      authDomain: "lusartrans-67e20.firebaseapp.com",
      databaseURL: "https://lusartrans-67e20.firebaseio.com",
      projectId: "lusartrans-67e20",
      storageBucket: "lusartrans-67e20.appspot.com",
      messagingSenderId: "4345423875",
    };
    if (!firebase.apps.length) this.app = firebase.initializeApp(configTest);
    else {
      this.app = firebase.app();
    }

    this.updateLocalState = this.updateLocalState.bind(this);
    this.database = this.app.database;
    this.databaseRef = firebase.database().ref().child("Users");
    this.state = {
      Users: [],
      login: false,
      User: "",
      acces: false,
      UserObject: { Access: [] },
      tempUsers: [],
      Client: false,
      UserName: "",
      store: false,
    };
  }

  CreatOrdersDisplay = (props) => (
    <NewOrders
      user={this.state.User}
      Users={this.state.Users}
      CCPrefix={this.state.UserObject.CCPrefix}
      DefaultCountry={this.state.UserObject.DefaultCountry}
      DefaultAgent={this.state.UserObject.DefaultAgent}
      Questions={this.state.UserObject.Questions}
    />
  );
  // CreatTrucksDisplay = (props) => (
  //   <TrucksDisplay
  //     user={this.state.User}
  //     Users={this.state.Users}
  //     CCPrefix={this.state.UserObject.CCPrefix}
  //     DefaultCountry={this.state.UserObject.DefaultCountry}
  //     DefaultAgent={this.state.UserObject.DefaultAgent}
  //     Questions={this.state.UserObject.Questions}
  //     Accountant={this.state.UserObject.Accountant}
  //   />
  // );
  CreatAgentDisplay = (props) => (
    <AgentsDisplay Access={this.state.UserObject.Access} />
  );

  handleClick() {}

  componentDidMount() {
    this.authSubscription = firebase
      .auth()
      .onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          if (firebase.auth().currentUser.phoneNumber) {
            this.setState({ login: true, store: true });
            return;
          }
          this.setState({ login: true });
          this.setState({ User: firebaseUser.email });
          firebase
            .database()
            .ref()
            .child("Users")
            .orderByChild("Email")
            .equalTo(firebaseUser.email)
            .once("value", (snap) => {
              snap.forEach((a) => {
                this.setState({ UserName: a.val().Name });
              });
            });
          // firebase
          //   .database()
          //   .ref()
          //   .child("MobileClients")
          //   .orderByChild("Email")
          //   .equalTo(firebaseUser.email)
          //   .once("child_added", (snap) => {
          //     this.setState({ Client: true });
          //   });
        } else {
          this.setState({ login: false, User: "" });
        }
      });

    // this.databaseRef.on("child_added", (snap) => {
    //   const response = snap.val();
    //   const key = snap.key;
    //   this.updateLocalState(response, key);
    // });
  }

  updateLocalState(response, key) {
    const { tempUsers } = this.state;

    tempUsers.push(response.Email);

    if (response.Email === this.state.User) {
      const tempUserObj = {
        Email: response.Email,
        Name: response.Name,
        Phone: response.Phone,
        DefaultCountry: response.DefaultCountry,
        DefaultAgent: response.DeafaultAgent,
        CCPrefix: response.CCPrefix,
        Questions: response.Questions,
        Access: response.Access,
        Accountant: response.Accountant,
      };
      this.setState({ UserObject: tempUserObj });
    }

    this.setState({ Users: tempUsers });
  }
  render() {
    return (
      <Provider key="provider" {...stores}>
        <Router>
          <section>
            {this.state.login && !this.state.store && (
              <HeaderSide
                user={this.state.UserName}
                Access={this.state.UserObject.Access}
              />
            )}
            <Route exact path="/" component={Page} />
            {/* <Route exact path="/test" component={TestComp} /> */}
            <Route exact path="/app" component={goToMobileApp} />
            <Route exact path="/policy" component={Policy} />
            <Route exact path="/truck-policy" component={Policy} />
            <div style={{ marginLeft: 10, marginRight: 10 }}>
              <Route path="/login" component={Login} />

              {this.state.login && (
                <section>
                  <Route
                    exact
                    path="/AgentsDisplay"
                    component={this.CreatAgentDisplay.bind(this)}
                  />
                  <Route exact path="/ClientsData" component={ClientsData} />
                  <Route exact path="/ActiveOrders" component={ActiveOrders} />
                  <Route
                    exact
                    path="/Orders"
                    component={this.CreatOrdersDisplay.bind(this)}
                  />

                  {!firebase.auth().currentUser.email.includes("operator") && (
                    <Route
                      exact
                      path="/RegisteredTrucks"
                      component={RegisteredTrucks}
                    />
                  )}
                  <Route
                    exact
                    path="/StoreCategories"
                    component={StoreCategories}
                  />
                  <Suspense fallback={"loading"}>
                    <Route exact path="/Map" component={Map} />
                  </Suspense>
                </section>
              )}
            </div>
          </section>
        </Router>
      </Provider>
    );
  }
}

export default App;
