import React, { Component } from "react";

export class Contact extends Component {
  render() {
    return (
      <div>
        <section id="contact" class="home-item home-item-3">
          <div class="container-80">
            <h2>Կապ</h2>
            <div class="contact">
              <div class="contact-left">
                <iframe
                  title="map"
                  width="100%"
                  height="250"
                  src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=khanjyan%2021%20yerevan+(door2door)&amp;ie=UTF8&amp;t=&amp;z=18&amp;iwloc=B&amp;output=embed"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                >
                  <a href="https://www.maps.ie/coordinates.html">
                    gps coordinates
                  </a>
                </iframe>
              </div>
              <div class="contact-right">
                <div>
                  <svg
                    class=""
                    width="30px"
                    height="30px"
                    enable-background="new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m256 0c-99.252 0-180 80.748-180 180 0 33.534 9.289 66.26 26.869 94.652l142.88 230.26c2.737 4.411 7.559 7.091 12.745 7.091h0.119c5.231-0.041 10.063-2.804 12.75-7.292l139.24-232.49c16.61-27.792 25.389-59.681 25.389-92.22 0-99.252-80.748-180-180-180zm128.87 256.82-126.59 211.37-129.9-209.34c-14.633-23.632-22.567-50.896-22.567-78.846 0-82.71 67.49-150.2 150.2-150.2s150.1 67.49 150.1 150.2c0 27.121-7.411 53.688-21.234 76.818z"
                      fill="#ff6600"
                    />
                    <path
                      d="m256 90c-49.626 0-90 40.374-90 90 0 49.309 39.717 90 90 90 50.903 0 90-41.233 90-90 0-49.626-40.374-90-90-90zm0 150.2c-33.257 0-60.2-27.033-60.2-60.2 0-33.084 27.116-60.2 60.2-60.2s60.1 27.116 60.1 60.2c0 32.683-26.316 60.2-60.1 60.2z"
                      fill="#ff6600"
                    />
                  </svg>
                  <p>Խանջյան 15</p>
                </div>
                <div>
                  <svg
                    class=""
                    width="30px"
                    height="30px"
                    enable-background="new 0 0 473.806 473.806"
                    version="1.1"
                    viewBox="0 0 473.81 473.81"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m374.46 293.51c-9.7-10.1-21.4-15.5-33.8-15.5-12.3 0-24.1 5.3-34.2 15.4l-31.6 31.5c-2.6-1.4-5.2-2.7-7.7-4-3.6-1.8-7-3.5-9.9-5.3-29.6-18.8-56.5-43.3-82.3-75-12.5-15.8-20.9-29.1-27-42.6 8.2-7.5 15.8-15.3 23.2-22.8 2.8-2.8 5.6-5.7 8.4-8.5 21-21 21-48.2 0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5-6-6.2-12.3-12.6-18.8-18.6-9.7-9.6-21.3-14.7-33.5-14.7s-24 5.1-34 14.7l-0.2 0.2-34 34.3c-12.8 12.8-20.1 28.4-21.7 46.5-2.4 29.2 6.2 56.4 12.8 74.2 16.2 43.7 40.4 84.2 76.5 127.6 43.8 52.3 96.5 93.6 156.7 122.7 23 10.9 53.7 23.8 88 26 2.1 0.1 4.3 0.2 6.3 0.2 23.1 0 42.5-8.3 57.7-24.8 0.1-0.2 0.3-0.3 0.4-0.5 5.2-6.3 11.2-12 17.5-18.1 4.3-4.1 8.7-8.4 13-12.9 9.9-10.3 15.1-22.3 15.1-34.6 0-12.4-5.3-24.3-15.4-34.3l-54.9-55.1zm35.8 105.3c-0.1 0-0.1 0.1 0 0-3.9 4.2-7.9 8-12.2 12.2-6.5 6.2-13.1 12.7-19.3 20-10.1 10.8-22 15.9-37.6 15.9-1.5 0-3.1 0-4.6-0.1-29.7-1.9-57.3-13.5-78-23.4-56.6-27.4-106.3-66.3-147.6-115.6-34.1-41.1-56.9-79.1-72-119.9-9.3-24.9-12.7-44.3-11.2-62.6 1-11.7 5.5-21.4 13.8-29.7l34.1-34.1c4.9-4.6 10.1-7.1 15.2-7.1 6.3 0 11.4 3.8 14.6 7l0.3 0.3c6.1 5.7 11.9 11.6 18 17.9 3.1 3.2 6.3 6.4 9.5 9.7l27.3 27.3c10.6 10.6 10.6 20.4 0 31-2.9 2.9-5.7 5.8-8.6 8.6-8.4 8.6-16.4 16.6-25.1 24.4-0.2 0.2-0.4 0.3-0.5 0.5-8.6 8.6-7 17-5.2 22.7l0.3 0.9c7.1 17.2 17.1 33.4 32.3 52.7l0.1 0.1c27.6 34 56.7 60.5 88.8 80.8 4.1 2.6 8.3 4.7 12.3 6.7 3.6 1.8 7 3.5 9.9 5.3 0.4 0.2 0.8 0.5 1.2 0.7 3.4 1.7 6.6 2.5 9.9 2.5 8.3 0 13.5-5.2 15.2-6.9l34.2-34.2c3.4-3.4 8.8-7.5 15.1-7.5 6.2 0 11.3 3.9 14.4 7.3l55.3 55.3c10.3 10.2 10.3 20.7 0.1 31.3z"
                      fill="#ff6600"
                    />
                    <path
                      d="m256.06 112.71c26.2 4.4 50 16.8 69 35.8s31.3 42.8 35.8 69c1.1 6.6 6.8 11.2 13.3 11.2 0.8 0 1.5-0.1 2.3-0.2 7.4-1.2 12.3-8.2 11.1-15.6-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3 3.7-15.6 11s3.5 14.4 10.9 15.6z"
                      fill="#ff6600"
                    />
                    <path
                      d="m473.26 209.01c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2 3.7-15.5 11-1.2 7.4 3.7 14.3 11.1 15.6 46.6 7.9 89.1 30 122.9 63.7 33.8 33.8 55.8 76.3 63.7 122.9 1.1 6.6 6.8 11.2 13.3 11.2 0.8 0 1.5-0.1 2.3-0.2 7.3-1.1 12.3-8.1 11-15.4z"
                      fill="#ff6600"
                    />
                  </svg>
                  <p>
                    <a href="tel:+37412600068">+374-12-60-00-68</a>
                    <br />
                    <a href="tel:+55626620">+374-55-626-620</a>
                  </p>
                </div>
                <div>
                  <svg
                    width="30px"
                    height="30px"
                    enable-background="new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m469.33 64h-426.67c-23.532 0-42.667 19.135-42.667 42.667v298.67c0 23.531 19.135 42.666 42.667 42.666h426.67c23.531 0 42.666-19.135 42.666-42.667v-298.67c0-23.532-19.135-42.667-42.667-42.667zm-426.67 21.333h426.67c1.572 0 2.957 0.573 4.432 0.897-36.939 33.807-159.42 145.86-202.29 184.48-3.354 3.021-8.76 6.625-15.479 6.625s-12.125-3.604-15.49-6.635c-42.859-38.613-165.35-150.67-202.28-184.47 1.478-0.324 2.866-0.899 4.439-0.899zm-21.334 320v-298.67c0-2.09 0.63-3.986 1.194-5.896 28.272 25.876 113.74 104.06 169.15 154.45-55.236 47.447-140.72 128.5-169.22 155.67-0.503-1.814-1.127-3.588-1.127-5.56zm448 21.334h-426.67c-1.704 0-3.219-0.594-4.81-0.974 29.447-28.072 115.48-109.59 169.74-156.01 7.074 6.417 13.536 12.268 18.63 16.858 8.792 7.938 19.083 12.125 29.771 12.125s20.979-4.188 29.76-12.115c5.096-4.592 11.563-10.448 18.641-16.868 54.268 46.418 140.29 127.93 169.74 156.01-1.591 0.38-3.104 0.974-4.81 0.974zm21.334-21.334c0 1.971-0.624 3.746-1.126 5.56-28.508-27.188-113.98-108.23-169.22-155.67 55.418-50.393 140.87-128.57 169.15-154.46 0.564 1.91 1.194 3.807 1.194 5.897v298.67z"
                      fill="#ff6600"
                    />
                  </svg>
                  <p>
                    <a href="mailto:support@d2d.am">support@d2d.am</a>
                  </p>
                </div>
                <div>
                  <svg
                    width="30px"
                    height="30px"
                    enable-background="new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m75 512h167v-182h-60v-60h60v-75c0-41.355469 33.644531-75 75-75h75v60h-60c-16.542969 0-30 13.457031-30 30v60h87.292969l-10 60h-77.292969v182h135c41.355469 0 75-33.644531 75-75v-362c0-41.355469-33.644531-75-75-75h-362c-41.355469 0-75 33.644531-75 75v362c0 41.355469 33.644531 75 75 75zm-45-437c0-24.8125 20.1875-45 45-45h362c24.8125 0 45 20.1875 45 45v362c0 24.8125-20.1875 45-45 45h-105v-122h72.707031l20-120h-92.707031v-30h90v-120h-105c-57.898438 0-105 47.101562-105 105v45h-60v120h60v122h-137c-24.8125 0-45-20.1875-45-45zm0 0"
                      fill="#ff6600"
                    />
                  </svg>

                  <p>
                    <a href="https://www.facebook.com/TMd2d/" target="_blank">
                      {" "}
                      Հետևեք մեզ Ֆեյսբուքում
                    </a>
                  </p>
                </div>
                <div>
                  <svg
                    width="30px"
                    height="30px"
                    enable-background="new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m75 512h362c41.355469 0 75-33.644531 75-75v-362c0-41.355469-33.644531-75-75-75h-362c-41.355469 0-75 33.644531-75 75v362c0 41.355469 33.644531 75 75 75zm-45-437c0-24.8125 20.1875-45 45-45h362c24.8125 0 45 20.1875 45 45v362c0 24.8125-20.1875 45-45 45h-362c-24.8125 0-45-20.1875-45-45zm0 0"
                      fill="#ff6600"
                    />
                    <path
                      d="m256 391c74.4375 0 135-60.5625 135-135s-60.5625-135-135-135-135 60.5625-135 135 60.5625 135 135 135zm0-240c57.898438 0 105 47.101562 105 105s-47.101562 105-105 105-105-47.101562-105-105 47.101562-105 105-105zm0 0"
                      fill="#ff6600"
                    />
                    <path
                      d="m406 151c24.8125 0 45-20.1875 45-45s-20.1875-45-45-45-45 20.1875-45 45 20.1875 45 45 45zm0-60c8.269531 0 15 6.730469 15 15s-6.730469 15-15 15-15-6.730469-15-15 6.730469-15 15-15zm0 0"
                      fill="#ff6600"
                    />
                  </svg>

                  <p>
                    <a
                      href="https://www.instagram.com/tm_door2door/"
                      target="_blank"
                    >
                      {" "}
                      Հետևեք մեզ Ինստագրամում
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;
