import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  DetailRow,
  Edit,
  ExcelExport,
  Filter,
  GridComponent,
  Inject,
  Page,
  PdfExport,
  Reorder,
  Resize,
  Sort,
  Toolbar,
  VirtualScroll,
} from "@syncfusion/ej2-react-grids";
import firebase from "firebase/app";
import "firebase/database";
import AppBar from "material-ui/AppBar";
import DatePicker from "material-ui/DatePicker";
import MenuItem from "material-ui/MenuItem";
//import MailEditor from "./tools";
import Paper from "material-ui/Paper";
import SelectField from "material-ui/SelectField";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { inject, observer } from "mobx-react";
import React from "react";
import Recorder from "react-mp3-recorder";
import "react-voice-recorder/dist/index.css";
import { ordersStore } from "../stores";
import TestComp from "../testComp";
import CommentComponent from "./commentComponent";
import CreateOrder from "./create-order";
import CustomAlert from "./CustomAlert";
import sound from "./notifi.mp3";
import NumberInput from "./NumberInput";

@inject("ordersStore")
@observer
class NewOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      test: false,
      audioDetails: {
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: null,
          m: null,
          s: null,
        },
      },
      access: false,
    };
    this.aaa = new Audio(sound);
  }
  handleAudioStop(data) {
    this.setState({ audioDetails: data });
  }
  handleAudioUpload = (file) => {
    this.sendVoice(file);
  };
  handleRest = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: null,
        m: null,
        s: null,
      },
    };
    this.setState({ audioDetails: reset });
  };
  componentDidMount() {
    this.setState({ access: !firebase.auth().currentUser.email });
    const { ordersStore } = this.props;
    if (!ordersStore.inited) {
      ordersStore.init();
    }
    let trrr = [];
    firebase
      .database()
      .ref()
      .child("InternalTrucks")
      .on("value", (sno) => {
        sno.forEach((e) => {
          trrr.push(e.val());
        });
      });
    String.prototype.splice = function (idx, rem, str) {
      return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
    };
    if (!!!ordersStore.settings === false) {
      let tempSettings = {
        visible: {},
        width: {},
      };
      if (this.gridInstance)
        this.gridInstance.getColumns().forEach((a) => {
          if (a.visible === undefined) {
            a.visible = true;
          }
          tempSettings.visible[a.field] = a.visible;
          tempSettings.width[a.field] = a.width;
        });
      ordersStore.setDefaultSettings(tempSettings);
    }
  }
  sendNotification = (massage, to) => {
    fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers: {
        Authorization:
          "key=AAAAlJYf2Tc:APA91bFc01tEi8LFLwYubqTv1NAlbFHW159LJnDeQt3jfaD2eyJEIm-mx8dniizpCVt1nIlWaHmcyLJ-ootL6z5CwyzC7dOMJcU8pqaDwDIJ4T5qKTmgHSU4X6WUaxkr8QjKTSbXeb6b",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: to,
        notification: {
          body: massage,
          title: "door2door",
          sound: "default",
        },
        data: {
          orderId: "chat",
        },
      }),
    });
  };

  clickHandler = (args) => {
    if (args.item.id === "excel") {
      this.gridInstance.excelExport();
    }
    if (args.item.id === "confirm") {
      this.props.ordersStore.Confirm();
    }
    if (args.item.id === "reject") {
      this.props.ordersStore.Reject();
    }
    if (args.item.id === "copy") {
      this.gridInstance.copy(false);
    }
    if (args.item.id === "wrap") {
      this.props.ordersStore.wrap();
    }
    if (args.item.id === "co") {
      this.CreateOrderShow(true);
    }
    if (args.item.id === "loadingMap") {
      this.CreateOrderShow(false, "loading");
    }
    if (args.item.id === "unloadingMap") {
      this.CreateOrderShow(false, "unloading");
    }
    if (args.item.id === "activate") {
      this.props.ordersStore.onActivateOrder();
    }
    if (args.item.id === "cancel") {
      this.props.ordersStore.onCancelOrder();
    }

    if (args.item.id === "sendOrder") {
      // this.props.ordersStore.createClientPriceMail();
      this.props.ordersStore.PrepareSendOrder();
    }

    if (args.item.id === "showAll") {
      // this.props.ordersStore.createClientPriceMail();
      this.props.ordersStore.ShowAll();
    }
    if (args.item.id === "quest") {
      // this.props.ordersStore.createClientPriceMail();
      this.props.ordersStore.follow();
    }

    if (args.item.id === "800") {
      // this.props.ordersStore.createClientPriceMail();
      this.props.ordersStore.setTarrif("800");
    }
    if (args.item.id === "900") {
      // this.props.ordersStore.createClientPriceMail();
      this.props.ordersStore.setTarrif("1000");
    }
    if (args.item.id === "700") {
      // this.props.ordersStore.createClientPriceMail();
      this.props.ordersStore.setTarrif("700");
    }
  };

  actionBegin = (args) => {
    if (args.requestType === "add") {
      args.data.date = new Date();
      args.data.destination = "Armenia";
      args.data.status = "new";
      args.data.country = this.props.ordersStore.UserInfo.DefaultCountry;
    }
    if (args.requestType === "edit") {
      console.log("dit");
    }
    if (args.requestType === "save") {
      if (args.data.client !== args.previousData.client) {
      }
      if (args.data.id === "") {
        this.props.ordersStore.onAddRow(args.data);
        this.gridInstance.refresh();
      } else {
        this.props.ordersStore.onAfterSaveCell(args.data);
      }
    }
  };

  rowDataBound(args) {
    args.row.classList.add(`class-for-${args.data["status"]}`);
  }
  rowSelected = (selected) => {
    this.props.ordersStore.setSelectedIndex(selected);
  };
  cellSelected = (selected) => {
    const key = this.gridInstance.columns[selected.cellIndex.cellIndex].field;

    this.props.ordersStore.setColorOptions(key, selected.cellIndex.rowIndex);
    this.props.ordersStore.setSelectedIndex(selected);
  };
  editTemplate = (data) => {
    return (
      <textarea
        style={{ width: "100%", height: 120, whiteSpace: "pre" }}
        defaultValue={data.shipperContacts}
        value={this.props.ordersStore.text}
        id="shipperContacts"
        placeholder="Shipper details"
        floatLabelType="Never"
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.target.value = e.target.value.splice(
              e.target.selectionStart,
              0,
              "\n"
            );
          }
        }}
        onChange={(e) => {
          this.props.ordersStore.text = e.target.value;
        }}
      />
    );
  };
  dimensions = (data) => {
    return (
      <textarea
        style={{ width: "100%", height: 120, whiteSpace: "pre" }}
        defaultValue={data.goodsBreaf}
        value={this.props.ordersStore.dimensions}
        id="goodsBreaf"
        placeholder="Dimensions"
        floatLabelType="Never"
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.target.value = e.target.value.splice(
              e.target.selectionStart,
              0,
              "\n"
            );
          }
        }}
        onChange={(e) => {
          this.props.ordersStore.dimensions = e.target.value;
        }}
      />
    );
  };
  autoCompleteTemplate = (data) => {
    return (
      <AutoCompleteComponent
        id="client"
        value={data.client}
        dataSource={this.props.ordersStore.Clients}
        fields={{ value: "CompanyName" }}
        change={(e) => this.props.ordersStore.setContactPerson(e.value)}
      />
    );
  };
  contactPersonsTemplate = (data) => {
    return (
      <AutoCompleteComponent
        id="clientContactPersonName"
        value={data.clientContactPersonName}
        dataSource={this.props.ordersStore.contactPersons}
        fields={{ value: "name" }}
      />
    );
  };

  closeDialog = () => {
    this.props.ordersStore.closeAlert();
  };

  customizeCell = (args) => {
    if (this.props.ordersStore.Orders.length > 0) {
      const index = this.props.ordersStore.Orders.findIndex(
        (a) => a.id === args.data.id
      );
      if (this.props.ordersStore.Orders[index].color !== undefined) {
        args.cell.style.color =
          this.props.ordersStore.Orders[index].color[args.column.field];
      }
      if (
        this.props.ordersStore.Orders[index].priceComment !== undefined &&
        args.column.field === "price" &&
        this.props.ordersStore.Orders[index].priceComment !== ""
      ) {
        args.cell.style.fontStyle = "italic";
        args.cell.style.fontWeight = "bold";
      }
    }
  };

  colorSetter = (args) => {
    if (this.props.ordersStore.colorObject !== null) {
      this.props.ordersStore.setColor(args.currentValue.hex);
    }
  };

  contextMenuClick = (args) => {
    if (args.item.id === "color") {
      this.props.ordersStore.onShowComment();
    }
  };

  handleMouseEnter(e) {
    this.paperInstace.style.top = `${e.clientY}px`;
    this.paperInstace.style.left = `${e.clientX}px`;
  }

  setEditMode = () => {
    const { ordersStore } = this.props;
    ordersStore.setEditMode();
  };
  actionComplete = (args) => {
    if (args.requestType === "columnstate") {
      const tempset = this.props.ordersStore.settings;
      args.columns.forEach((a) => {
        tempset.visible[a.field] = a.visible;
      });
      this.props.ordersStore.setSettings(tempset);
    }
  
  };
  resizeStop = () => {
    const tempset = this.props.ordersStore.settings;
    this.gridInstance.getColumns().forEach((a) => {
      tempset.width[a.field] = a.width;
    });
    this.props.ordersStore.setSettings(tempset, true);
  };
  sendOrder = (number) => {
    this.props.ordersStore.sendOrder(number);
  };
  setNumber = (number) => {
    this.props.ordersStore.selectedNumber = number;
  };
  sendVoice = (file) => {
    this.props.ordersStore.sendVoice(file);
  };
  closeSendOrder = () => {
    this.props.ordersStore.ShowSendOrder = false;
  };
  CreateOrderClose = () => {
    this.props.ordersStore.showCreateOrder = false;
    this.props.ordersStore.unfollow();
  };
  CreateOrderShow = (creating, loading) => {
    this.props.ordersStore.creating = creating;
    if (!creating) this.props.ordersStore.showMap(loading);
    else {
      ordersStore.mapCoords = null;
    }
    this.props.ordersStore.showCreateOrder = true;
  };
  render() {
    const { ordersStore } = this.props;
    const { Orders, OnQuest } = ordersStore;
    let sortedOrders = Orders.slice().sort((a, b) => {
      return b.compareDate - a.compareDate;
    });

    const contextMenuItems = [
      "AutoFit",
      "AutoFitAll",
      "SortAscending",
      "SortDescending",
      "Copy",
      "Edit",
      "Delete",
      "Save",
      "Cancel",
      "PdfExport",
      "ExcelExport",
      "CsvExport",
      "FirstPage",
      "PrevPage",
      "LastPage",
      "NextPage",
      { text: "Comment", target: ".e-content", id: "color", active: "false" },
    ];

    const settings = {
      mode: "Cell",
      type: "Multiple",
    };

    return (
      <MuiThemeProvider>
        <React.Fragment>
          {/* {this.state.test ? (
            <TestComp title="first" />
          ) : (
            <TestComp title="second" />
          )}
          <button
            onClick={() => this.setState({ test: !this.state.test })}
            style={{ marginTop: 50 }}
          >
            test
          </button> */}
          <AppBar
            title={
              <div>
                <span
                  style={{
                    marginLeft: 20,
                    cursor: "pointer",
                    color: "white",
                    marginRight: 20,
                  }}
                >
                  {ordersStore.completedCount}
                </span>
                <span
                  style={{
                    marginLeft: 20,
                    cursor: "pointer",
                    color: "white",
                    marginRight: 20,
                  }}
                >
                  {ordersStore.Percent}
                </span>
                <span
                  style={{
                    marginLeft: 20,
                    cursor: "pointer",
                    color: "white",
                    marginRight: 20,
                  }}
                >
                  {
                    sortedOrders?.filter(
                      (item) => !item.payment && item.status !== "canceled"
                    ).length
                  }
                </span>
                <span
                  style={{
                    marginLeft: 20,
                    cursor: "pointer",
                    color: "white",
                    marginRight: 20,
                  }}
                >
                  {sortedOrders?.length}
                </span>
                <span
                  onClick={() => (ordersStore.ClientOrdersSwitcher = false)}
                  style={{ color: "white", cursor: "pointer" }}
                >
                  Orders
                </span>
              </div>
            }
            showMenuIconButton={false}
            titleStyle={{ fontSize: 16, lineHeight: 3 }}
            style={{
              zIndex: 0,
              marginTop: 40,
              height: 45,
              lineHeight: 1,
              backgroundColor: "#414453",
            }}
          >
            {ordersStore.User === "k.soghoyan@d2d.am@lusartrans.am" && (
              <SelectField
                style={{ width: 140, marginLeft: 10, marginRight: 10 }}
                labelStyle={{ color: "white" }}
                value={ordersStore.value}
                onChange={ordersStore.handleChangeMinDate}
              >
                {ordersStore.Users.map((user, id) => {
                  return <MenuItem key={id} value={user} primaryText={user} />;
                })}
              </SelectField>
            )}
            <DatePicker
              style={{ display: "inline-block", width: 100 }}
              fullWidth={true}
              onChange={ordersStore.handleChangeMinDate}
              autoOk={true}
              defaultDate={ordersStore.minDate}
              inputStyle={{ color: "white" }}
            />
            <DatePicker
              style={{ display: "inline-block", width: 100 }}
              fullWidth={true}
              onChange={ordersStore.handleChangeMaxDate}
              autoOk={true}
              defaultDate={ordersStore.maxDate}
              inputStyle={{ color: "white" }}
            />
          </AppBar>
          <div style={{ width: 120, borderStyle: "inset" }}></div>

          <div className="control-pane" />
          <div
            className="control-section"
            onContextMenuCapture={this.handleMouseEnter.bind(this)}
          >
            {!ordersStore.Loading && (
              <GridComponent
                GroupSettingsModel={{ showGroupedColumn: true }}
                dataSource={sortedOrders}
                toolbar={ordersStore.toolbarOptions}
                editSettings={ordersStore.editSettings}
                gridLines="Both"
                ref={(grid) => (this.gridInstance = grid)}
                toolbarClick={this.clickHandler}
                enableHover={true}
                contextMenuItems={contextMenuItems}
                allowExcelExport={true}
                allowPdfExport={true}
                allowResizing={true}
                allowReordering={true}
                allowTextWrap={ordersStore.isWraped}
                showColumnChooser={true}
                actionBegin={this.actionBegin}
                selectionSettings={settings}
                rowDataBound={this.rowDataBound.bind(this)}
                //rowSelected={this.rowSelected.bind(this)}
                cellSelected={this.cellSelected.bind(this)}
                allowFiltering={true}
                filterSettings={{ type: "Menu" }}
                enableVirtualization={false}
                queryCellInfo={this.customizeCell.bind(this)}
                pageSettings={{ pageSize: 20 }}
                allowPaging={true}
                width="auto"
                height={window.innerHeight * 0.8}
                contextMenuClick={this.contextMenuClick.bind(this)}
                beforeOpenColumnChooser={this.columns}
                actionComplete={this.actionComplete.bind(this)}
                resizeStop={this.resizeStop.bind(this)}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="payment"
                    headerText="Payment"
                    width={ordersStore.settings.width.payment || "42px"}
                  />
                  <ColumnDirective
                    field="goodsBreaf"
                    headerText="Goods"
                    //  editTemplate={this.dimensions.bind(this)}
                    width={ordersStore.settings.width.goodsBreaf || "80px"}
                    visible={
                      ordersStore.settings.visible.goodsBreaf === undefined
                        ? true
                        : ordersStore.settings.visible.goodsBreaf
                    }
                  />
                  <ColumnDirective
                    field="id"
                    width={ordersStore.settings.width.id || 90}
                    isPrimaryKey
                    isIdentity
                    headerText="CC"
                    textAlign="Right"
                    visible={
                      ordersStore.settings.visible.id === undefined
                        ? true
                        : ordersStore.settings.visible.id
                    }
                  />
                  <ColumnDirective
                    field="destinationType"
                    headerText="Type"
                    width={"0px"}
                  />
                  <ColumnDirective
                    allowReordering
                    allowSorting
                    field="status"
                    headerText="Status"
                    width={ordersStore.settings.width.status || 80}
                    visible={
                      ordersStore.settings.visible.status === undefined
                        ? true
                        : ordersStore.settings.visible.status
                    }
                  />
                  <ColumnDirective
                    field="selectedTruck"
                    headerText="selectedTruck"
                    width={ordersStore.settings.width.selectedTruck || 80}
                    visible={
                      ordersStore.settings.visible.status === undefined
                        ? true
                        : ordersStore.settings.visible.status
                    }
                  />
                  <ColumnDirective
                    allowSorting={true}
                    field="date"
                    width={ordersStore.settings.width.date || "90"}
                    //  editType="datepickeredit"
                    headerText="Date"
                    visible={
                      ordersStore.settings.visible.date === undefined
                        ? true
                        : ordersStore.settings.visible.date
                    }
                  />
                  <ColumnDirective
                    field="loadingdate"
                    width={ordersStore.settings.width.date || "90"}
                    // editType="datepickeredit"
                    headerText="loading date"
                    visible={
                      ordersStore.settings.visible.date === undefined
                        ? true
                        : ordersStore.settings.visible.date
                    }
                  />
                  <ColumnDirective
                    field="loadingAddress"
                    headerText="Loading Address"
                  />
                  <ColumnDirective
                    field="unloadingAddress"
                    headerText="unloading address"
                    width={ordersStore.settings.width.unloadingAddress || "180"}
                    // editType="dropdownedit"
                    // edit={countryParams}
                    visible={
                      ordersStore.settings.visible.country === undefined
                        ? true
                        : ordersStore.settings.visible.country
                    }
                  />
                  <ColumnDirective
                    field="client"
                    width={ordersStore.settings.width.client || "100"}
                    headerText="Client"
                    //  editTemplate={this.autoCompleteTemplate.bind(this)}
                    visible={
                      ordersStore.settings.visible.client === undefined
                        ? true
                        : ordersStore.settings.visible.client
                    }
                  />
                  <ColumnDirective
                    field="price"
                    width={ordersStore.settings.width.price || 90}
                    headerText="Price"
                    // format="C0"
                    visible={
                      ordersStore.settings.visible.price === undefined
                        ? true
                        : ordersStore.settings.visible.price
                    }
                    editType="numericedit"
                  />
                  <ColumnDirective
                    field="comment"
                    width={ordersStore.settings.width.comment || 80}
                    headerText="Commetns"
                    visible={
                      ordersStore.settings.visible.comment === undefined
                        ? true
                        : ordersStore.settings.visible.comment
                    }
                  />
                  <ColumnDirective
                    field="to"
                    width={ordersStore.settings.width.to || 80}
                    headerText="To"
                    defaultValue="Armenia"
                    visible={
                      ordersStore.settings.visible.to === undefined
                        ? true
                        : ordersStore.settings.visible.to
                    }
                  />
                  <ColumnDirective
                    field="clientContactPersonName"
                    width={
                      ordersStore.settings.width.clientContactPersonName ||
                      "100"
                    }
                    headerText="Contact"
                    editTemplate={this.contactPersonsTemplate.bind(this)}
                    visible={
                      ordersStore.settings.visible.clientContactPersonName ===
                      undefined
                        ? true
                        : ordersStore.settings.visible.clientContactPersonName
                    }
                  />
                  <ColumnDirective
                    field="shipper"
                    width={ordersStore.settings.width.shipper || "100"}
                    headerText="Shipper"
                    visible={false}
                  />
                  <ColumnDirective
                    field="shipperContacts"
                    headerText="Shipper Contacts"
                    editTemplate={this.editTemplate.bind(this)}
                    width={ordersStore.settings.width.shipperContacts || "120"}
                    visible={
                      ordersStore.settings.visible.shipperContacts
                        ? true
                        : ordersStore.settings.visible.shipperContacts
                    }
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    ColumnChooser,
                    Reorder,
                    Toolbar,
                    Resize,
                    Sort,
                    ContextMenu,
                    Filter,
                    Page,
                    ExcelExport,
                    Edit,
                    PdfExport,
                    DetailRow,
                    VirtualScroll,
                  ]}
                />
              </GridComponent>
            )}{" "}
          </div>
          <CustomAlert
            open={this.props.ordersStore.AlertOpen}
            alertsms={this.props.ordersStore.alertsm}
            closeDialog={this.closeDialog.bind(this)}
          />
          {OnQuest && (
            <Paper
              zDepth={3}
              style={{
                width: "80%",
                position: "absolute",
                height: "80%",
                top: 40,
                left: 0,
                bottom: 0,
                right: 0,
                backgroundColor: "white",
                marginLeft: "auto",
                marginRight: "auto",
                zIndex: 99999,
                padding: 20,
                overflowY: "auto",
              }}
            ></Paper>
          )}

          <div
            ref={(paper) => (this.paperInstace = paper)}
            style={{ position: "absolute", top: 0 }}
          >
            {this.props.ordersStore.showComment && (
              <CommentComponent
                defaultValue={this.props.ordersStore.comment}
                closeComment={this.props.ordersStore.closeComment}
                ordersStore={this.props.ordersStore}
              />
            )}
          </div>
          <div>
            {ordersStore.ShowSendOrder && (
              <NumberInput
                close={this.closeSendOrder}
                sendOrder={this.sendOrder}
                trucks={ordersStore.activeTrucks}
                setNumber={this.setNumber}
              />
            )}
          </div>
          <span style={{ position: "absolute", bottom: 0 }}>
            version 10.1 door2door
          </span>
          {ordersStore.showCreateOrder && (
            <div
              style={{
                position: "absolute",
                top: "20%",
                left: "30%",
                width: "60%",
              }}
            >
              <CreateOrder
                center={ordersStore.mapCoords}
                creating={ordersStore.creating}
                close={this.CreateOrderClose}
              />
            </div>
          )}

          {ordersStore.ShowSendOrder && (
            <div style={{ position: "absolute", top: "20%", left: "80%" }}>
              <Recorder
                onRecordingComplete={this.handleAudioUpload}
                onRecordingError={this._onRecordingError}
              />
            </div>
          )}
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}
export default NewOrder;
